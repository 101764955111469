 /* body {
     margin: 0;
     padding: 0;
     background-color: #f5f5f5;
     font-family: "Open Sans", sans-serif;
 } */
 
 .page-container {
     overflow: hidden;
 }
 
 .page-top {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-evenly;
     margin-top: -96px;
     overflow: hidden;
     background-color: #ffc300;
     width: 120%;
     height: 400px;
     padding: 0 20%;
     border-bottom-left-radius: 50%;
     border-bottom-right-radius: 50%;
 }
 
 .pageDescription {
     text-align: left;
 }
 
 .page-top h1 {
     font-family: "Nunito Sans";
     font-size: 40px;
     font-weight: 900;
     letter-spacing: 0;
     line-height: 48px;
     margin-right: 80px;
 }
 
 .pageDescription h4 {
     font-family: "Nunito Sans";
     font-size: 20px;
     font-weight: 100;
     letter-spacing: 0;
     line-height: 15px;
     margin-left: 125px;
     text-transform: capitalize;
 }
 
 .pageReturn {
     text-align: end;
     padding-top: 40px;
}

@media only screen and (max-width: 768px) and (min-width: 359px) {
    .pageReturn {
        padding-top: 70px;
    }
}
 
 .card-1 {
     background-color: #f5f5f5;
     border-radius: 10px;
     margin-left: -14px;
     margin-bottom: 17px;
     padding: 16px 14px 1px 14px;
     box-shadow: 0px 8px 12px rgb(23 29 33 / 14%);
 }
 
 .card-1 h4 {
     text-align: left;
     text-transform: capitalize;
 }
 
 .card-1 select {
     border: 1px solid #fff;
     border-radius: 4px;
     box-sizing: border-box;
     margin-top: 20px;
     margin-bottom: 16px;
     resize: vertical;
     width: auto;
     height: 41px;
     margin-right: 10px;
     padding-left: 20px;
 }
 
 input[type=file] {
     font-size: 12px;
 }
 
 .image-upload {
     width: 70%;
     height: 30%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     margin-left: 14%;
     margin-top: 10px;
     margin-bottom: 20px;
     background-color: #F7EAC5;
 }
 
 .card-1 img {
     width: 40%;
     margin-top: 40px;
     margin-left: 40px;
 }
 
 .second-icons {
     width: 100%;
     height: 20%;
     display: flex;
     flex-direction: row;
     /* border: 1px solid #ccc; */
     border-radius: 4px;
     /* padding-left: 9px; */
 }
 
 .second-icons img {
     width: 60%;
     height: 50%;
 }
 
 .second-icons>div {
     border: 1px solid #Fcc360;
     border-radius: 4px;
     display: flex;
     flex-direction: column;
     background-color: #F7EAC5;
     margin: 8px 8px 8px;
 }
 
 .second-icons h6 {
     margin-top: -45px;
     margin-left: 30px;
 }
 
 button[type=submit] {
     margin-top: 16px;
 }
 
 form {
     border-radius: 5px;
     background-color: #fff;
     width: 100%;
     margin-left: -5px;
     padding-left: 35px;
     padding-top: 20px;
     padding-right: 22px;
     padding-bottom: 15px;
     margin-bottom: 30px;
 }

 .form-error {
     color: #dc3545 !important;
 }
