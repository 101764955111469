@import url(https://fonts.googleapis.com/css?family=Lato:700);

/* 
* { background-color: rgba(255,0,0,.2); }
* * { background-color: rgba(0,255,0,.2); }
* * * { background-color: rgba(0,0,255,.2); }
* * * * { background-color: rgba(255,0,255,.2); }
* * * * * { background-color: rgba(0,255,255,.2); } */

/* =================================
    COLOR (use as variables - copy, paste, search, replace)
====================================

yellow          #FFC300;
yellow-dark     #FFB600;
white           #FFFFFF;
gray-light      $F5F5F5;
gray            #CCCCCC;
gray-dark       #2B3841;
black           #171D21
green           #00BA54;
green-dark      #004C22;
red             #F8382B;
red-dark        #661414;

*/


/* =================================
    TYPOGRAPHY
==================================== */

/* type - feel free to override color with !important */
/*  also includes responsive sizes for small screen headlines */

h1{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 40px;
    color: #171D21;
    letter-spacing: 0;
    text-align: center;
    line-height: 48px;
}

h1.left{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 40px;
    color: #171D21;
    letter-spacing: 0;
    text-align: left;
    line-height: 48px;
}

h2{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 32px;
    color: #171D21;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
}

h2.left{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 32px;
    color: #171D21;
    letter-spacing: 0;
    text-align: left;
    line-height: 40px;
}

h3{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 24px;
    color: #171D21;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
}

h3.left{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 24px;
    color: #171D21;
    letter-spacing: 0;
    text-align: left;
    line-height: 32px;
}

h4{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 14px;
    color: #171D21;
    letter-spacing: 2px;
    text-align: center;
    line-height: 21px;
    text-transform: uppercase;
}

h4.left{
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 14px;
    color: #171D21;
    letter-spacing: 2px;
    text-align: left;
    line-height: 21px;
}

p{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    color: #2B3841;
    letter-spacing: 0;
    line-height: 24px;
}

p.center{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    color: #2B3841;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

p.small{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    color: #2B3841;
    letter-spacing: 0;
    line-height: 24px;
}

p.small_center{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    color: #2B3841;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.numbers_big{
    /* font-family: OpenSansCondensed-Bold; */
    font-family: 'Open Sans Condensed';
    font-weight: 700;
    font-size: 40px;
    color: #181D21;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
}

.numbers_small{
    font-family: 'Open Sans Condensed';
    font-size: 24px;
    color: #171D21;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
}

.numbers_super_small{
    font-family: 'Open Sans Condensed';
    font-size: 16px;
    color: #171D21;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
}


/* =================================
    Global Styles
==================================== */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  background-color: #f5f5f5 !important;
}

button.primary{
    background-color: #171D21;
    border: none;
    color: #F5F5F5;
    text-decoration: none;
    display: inline-block;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1.71px;
    text-align: center;
    padding: 15px 40px;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s;
}

button.primary:hover{
    opacity: 0.8;
    color: #FFFFFF;
}

button.primary:focus, button.primary:active {
   outline: none !important;
   box-shadow: none;
}

/* form elements */

input{
    padding: 16px;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 0px !important;
}

textarea{
    padding: 16px;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 0px !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

div.title-background-parent {
  width: 100%;
  height: 506px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

div.title-background-shape {
    width: 140%;
    height: 506px;
    position: absolute;
    top: 0;
    left: -20%;
    padding: 0 20%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    overflow: hidden;
    background-color: #FFC300;
    opacity: 0.88;
    z-index: -1;
}

/* data components */

.data-container{
    width: 100%;
    height: 88px;
    background-color: #F5F5F5;
    border-radius: 4px;
    order: 1;
    padding: 3px;
}

.data-container-value{
    width: 100%;
    height: 48px;
    background-color: #FFFFFF;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    order: 1;
}

.data-container-value:first-child{
    text-align: center;
    padding-top: 8px;
}

.data-container-value-arrow{
    margin-top: -28px;
}

.data-container-value sup{
    top: -8px;
}

.data-container-label{
    text-align: center;
    padding-top: 6px;
}

.data-container-two{
    margin-left: 16px;
}

.data-container-mobile{
    width: 100%;
}

/*  modals  */

body.modal-open .modal {
    display: flex !important;
    height: 100%;
} 

body.modal-open .modal .modal-dialog {
    margin: auto;
}

.modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal-content{
    border: 0px !important;
    border-radius: 8px;
}

.modal-body{
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: 0 24px 18px -10px rgba(23,29,33,0.08);
}

.modal-header{
    border-bottom: 1px solid #F5F5F5;
    height: 56px;
    padding-left: 24px;
}

.modal-header p{
    margin-bottom: -8px;
}

.modal-header .close{
    color: #171D21;
    font-size: 32px;
    opacity: 1 !important;
    margin-top: -20px !important;
    margin-right:-8px !important;
}

.modal-backdrop.show{
    background-color: rgba(43,56,65,0.80);
}

/* tooltips */

.tooltip-inner {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 10px;
    background-color: #171D21 !important;
    color: #fff;
    margin-top: 18px;
    padding: 8px;
    opacity: 1.0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #171D21 !important;
    margin-bottom: -18px;
}

/* animations  */

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fadein{
    -webkit-animation: fadein 0.55s;
            animation: fadein 0.55s;
}


.global-header {
    background-color: #ffc300;
    z-index: 1049;
}

.navbar {
    min-height: 104px;
    border-top-width: 6px;
    border-top-style: solid;
}

.logo-parent-div{
    float: left;
}

.customer-logo-parent-div{
    float: left;
    height: 68px;
    width: 80px;
    margin-left: -16px;
    padding: 12px 12px 11px 12px;
    border-radius: 0px 8px 8px 0px;
    text-align: center;
}

.customer-logo {
    max-width: 100px;
    max-height: 100%;
    margin: auto;
}

.custom-logo{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.customer-logo-nav-link {
    padding: 0;
    display: inline-block;
    height: 100%;
}

.kuja-kuja-logo-nav-link {
    padding: 12px 0 0 12px;
    float: left;
}

.customer-logo-nav-link span.title-text {
    font-family: 'Nunito Sans', sans-serif !important;
    padding-left: 10px;
    color: #000000;
    font-size: 16px;
}

.navbar-toggler {
    padding: 0;
    margin-right: 16px;
    border: none;
    outline: none;
}

.global-header .navbar-toggler-icon {
    width: 32px;
    margin-right: 0;
    background-image: url(/static/media/hamburger.acbf4623.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.powered-by {
    font-family: 'Nunito Sans', sans-serif !important;
    background-color: rgb(255, 195, 0);
    height: 24px;
    font-size: 11px;
    padding: 5px 5px 5px 10px;
    font-weight: 900;
    border-bottom: 1px solid #e5b200;
    color: #000000;
}

nav .navbar-nav li a {
    font-family: 'Nunito Sans', sans-serif;
    color: #171D21 !important;
    font-size: 16px;
    margin-right: 16px;
}

nav .navbar-nav li a.navbar_active {
    border-bottom: #171D21 1px solid;
}

nav .collapse.show .navbar-nav {
    margin-top: 32px;
    margin-bottom: 32px;
}

nav .collapse.show .navbar-nav li {
    padding-left: 24px;
}

nav .collapse.show .navbar-nav li a {
    margin: 16px;
    padding: 0;
}

.dropdown {
    padding-top: 8px;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    margin-right: 16px;
}

.dropdown-menu {
    left: -80px;
    padding: 20px;
    min-width: 200px;
    border-color: #FFFFFF;
    box-shadow: 0 10px 12px -6px rgba(12, 16, 19, 0.24);
    border-radius: 8px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #000000;
}

.arrow-up {
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: 117px;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #FFFFFF transparent;
    padding: 0 !important;
}

@media screen and (min-width: 992px) {
    .dropdown-menu {
        left: -150px;
    }
}

@media screen and (max-width: 992px) {
    .dropdown {
        padding-left: 16px;
    }

    .arrow-up {
        left: 12px;
    }
}

/* overrides */

.badge-secondary {
    background-color: #CCC;
}

/* top */

.globalfilter-container {
    position: absolute;
    top: 104px;
    width: calc(100% - 32px);
    margin: 16px;
    z-index: 1045;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0 9px 12px -6px rgba(23, 29, 33, 0.14);
}

.globalfilter-desktop {
    display: flex;
    flex-direction: row;
    padding: 16px 0 8px;
}

.globalfilter-mobile {
    display: none;
}

/* drop-downs / selects */
/* note: these need renaming */

.map-controls-button {
    position: relative;
    width: 150px;
    height: 32px;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: #F5F5F5;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #171D21;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.map-controls-button-caret {
    display: inline-block;
    position: absolute;
    right: 6px;
    margin-right: 6px;
    color: #CCC;
}

.map-controls-popover {
    z-index: 999999;
    min-width: 192px;
    margin-top: 27px;
    padding: 8px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 18px 24px -6px rgba(23, 29, 33, 0.14);
}

.map-controls-popover.search-results p {
    padding-left: 8px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.map-controls-popover.search-results p.globalfilter-search-hidden {
    display: none;
}

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
    height: 24px;
    border-color: transparent;
    border-bottom-color: white;
}
.bs-popover-bottom .arrow::before {
   border-color: transparent;
}

.arrow {
    border-bottom: none !important;
}

.popover-body {
    max-height: 560px;
    overflow-y: scroll;
}

.map-controls-popover-list {
    list-style: none;
    margin-left: -40px;
    margin-bottom: 4px;
}

.map-controls-popover-list li {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #181D21;
    transition: all .3s;
    -webkit-user-select: none;
            user-select: none;
}

.map-controls-popover-list li:hover {
    background-color: #F5F5F5;
}

.map-controls-popover-list label {
    margin-top: 4px;
}

.map-controls-popover-list label input {
    margin-top: 2px;
}

/* search */

.globalfilter-search-container {
    position: relative;
    padding: 0 16px;
}

.map-controls-popover .map-controls-search {
    /* search input within dropdown */
    width: 100%;
}

/* note: these need renaming */
.map-controls-search-icon {
    position: absolute;
    top: 4px;
    left: 28px;
    width: 16px;
    color: #CCC;
}

.map-controls-search {
    width: 155px;
    height: 32px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    background-color: #F5F5F5;
    border: none !important;
    border-radius: 32px;
    padding-left: 36px;
    transition: all .2s;
}

.map-controls-popover .map-controls-search {
    /* search input within dropdown */
    width: 100%;
}

.map-controls-search.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

/* countries, locations & service points */

.globalfilter-locations-container {
    display: flex;
    flex: 2 1;
    flex-grow: 0;
    flex-basis: 80px;
    padding: 0 16px;
    border-left: solid 1px #F5F5F5;
}

.globalfilter-locations-spacer {
    width: 16px;
    height: 100%;
}

/* calendar */

.globalfilter-calendar-container {
    border-left: solid 1px #F5F5F5;
    padding: 0 10px 10px 10px;
}

.date-pickers-holder {
    display: flex;
    flex-direction: row;
}

.date-pickers-holder span.dash {
    padding: 0 10px;
    font-size: 20px;
    color: #ADADAD;
}

.date-pickers-holder span.dash.hidden {
    display: none;
}

.date-pickers-holder .date-picker-enddate.hide-date {
    display: none;
}

button.left-right-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background-color: #FFC300;
    cursor: pointer;
}

button.left-right-button:active {
    background-color: #E5E5E5;
}

button.left-right-button.left-button {
    margin-right: 10px;
}

button.left-right-button.right-button {
    margin-left: 10px;
}

button.left-right-button div.left-right-arrow {
    position: absolute;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

button.left-right-button div.left-right-arrow.left-arrow {
    margin-right: 2px;
    border-right: 7px solid white;
}

button.left-right-button div.left-right-arrow.right-arrow {
    margin-left: 2px;
    border-left: 7px solid white;
}

.date-pickers-holder .custom-date-parent {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    padding: 14px 0 !important;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.date-pickers-holder .custom-date-parent button.custom-date {
    width: 100px;
    font-size: 0.75rem;
    font-weight: bold;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.date-pickers-holder .custom-date-parent button.custom-date.selected {
    background-color: #FFC300;
    border-color: #FFC300;
}

h5.choose-from-end-date {
    margin-bottom: 5px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    clear: both;
}

/* react-datepicker overrides */
.react-datepicker-wrapper input {
    width: 85px;
    background: #F5F5F5;
    padding: 6px !important;
    border: none !important;
    font-size: 12px !important;
    color: #171D21;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.react-datepicker-wrapper input:focus {
    background-color: #2B3841;
    color: transparent;
    text-shadow: 0 0 0 #FFF;
    -webkit-user-select: none;
            user-select: none;
}

.react-datepicker__input-container {
    -webkit-user-select: none;
            user-select: none;
}

.react-datepicker-popper {
    z-index: 2000;
    margin-top: 26px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #FFF;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: 0;
}

.react-datepicker {
    display: inline-block;
    z-index: 2000;
    border: none;
    border-radius: 8px;
    box-shadow: 0 18px 24px -6px rgba(23, 29, 33, 0.14);
    font-family: 'Open Sans', sans-serif !important;
    font-size: 12px !important;
    color: #171D21;
}

.react-datepicker__triangle {
    border: none;
}

.react-datepicker div:not(.react-datepicker__triangle) {
    padding: 2px;
}

.react-datepicker__navigation {
    margin-top: 12px !important;
}

.react-datepicker__header {
    background-color: #FFF;
    border-bottom: none;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
}

.react-datepicker__current-month {
    font-weight: normal;
    font-size: 0.844rem;
}

.react-datepicker__day, .react-datepicker__time-name {
    width: 1.5rem;
    margin: 0.15rem;
    line-height: 1.1rem;
}

.react-datepicker__day--keyboard-selected {
    background-color: #FFF;
    color: #000;
}

.react-datepicker__day:hover  {
    background-color: #FFDE99;
    color: #000;
}

.react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__day--in-range {
    background-color: #FFBF30;
    color: #FFF;
}

.react-datepicker__month--selecting-range  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #FFBF30;
    color: #FFF;
}

.react-datepicker__month--selecting-range  .react-datepicker__day--in-range.react-datepicker__day--outside-month {
    background-color: #FFF;
    color: #FFF;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: #FFF;
    color: #000;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month {
    color: #FFF;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__day--outside-month):hover {
    background-color: #FFDE99;
}

.react-datepicker__day--disabled:hover {
    background-color: #FFF;
    color: #CCC;
}

.react-datepicker__day--outside-month, .react-datepicker__day--outside-month:hover {
    background-color: #FFF;
    color: #FFF;
    cursor: default;
}

/* service types */

.globalfilter-service-container {
    display: flex;
    flex: 4 1;
    padding: 0 16px;
    border-left: solid 1px #F5F5F5;
}

.globalfilter-service-container div {
    display: flex;
    flex-flow: row wrap;
}

.service-toggle-off, .service-toggle-on {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border: none;
    border-radius: 4px;
    margin-bottom: 8px;
}

.service-toggle-off img, .service-toggle-on img {
    width: 16px;
    height: 16px;
    margin-left: -4px;
}

.service-toggle-off:hover, .service-toggle-on:hover {
    opacity: 0.8;
}

.service-toggle-off:focus, .service-toggle-off:active, .service-toggle-on:focus, .service-toggle-on:active {
    outline: none !important;
    box-shadow: none !important;
}

.service-toggle-off {
    background-color: #F5F5F5;
    font-size: 14px;
}

.service-toggle-off img {
    margin-top: -4px;
}

.service-toggle-off:hover {
    background-color: #F5F5F5;
    color: #CCC;
}

.service-toggle-off:focus, .service-toggle-off:active {
    background-color: #F5F5F5 !important;
}

.service-toggle-on {
    background-color: #FFC300;
    outline: none !important;
}

.service-toggle-on img {
    margin-top: -8px;
}

.service-toggle-on:hover {
    background-color: #FFC300;
    color: #FFF;
}

.service-toggle-on:focus, .service-toggle-on:active {
    background-color: #FFC300 !important;
    color: #FFF !important;
}


/* mobile */

/* fix for dropdown height for short screens */
@media only screen and (max-height: 700px) {

    .popover-body {
        max-height: 400px;
        box-shadow: inset 0 -40px 40px -8px #F5F5F5;
    }

}


@media only screen and (max-width: 992px) {

    /* top */

    .globalfilter-container {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .globalfilter-desktop {
        display: none;
    }

    .globalfilter-mobile {
        display: block;
    }

    .globalfilter-mobile .navbar {
        min-height: 60px !important;
        padding: 0 !important;
        border: none;
        border-radius: 8px;
    }

    .globalfilter-mobile-brand {
        height: 60px;
    }

    .globalfilter-mobile-brand p {
        margin-top: 14px;
        padding-left: 28px;
    }

    .globalfilter-back-button {
        padding: 16px 16px 16px 24px;
        line-height: 0;
    }

    .globalfilter-back-button p {
        margin-top: -22px;
    }

    .globalfilter-mobile .navbar-toggler-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background-image: url(/static/media/mobile-controls-arrow.4f62b1f0.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .globalfilter-mobile .navbar-collapse {
        border-top: solid 1px #F5F5F5;
    }

    .globalfilter-mobile-container {
        padding: 16px;
    }

    /* filter buttons */
    .globalfilter-mobile-button {
        position: relative;
        width: 50%;
        height: 48px;
        margin-bottom: 16px;
        padding: 8px 8px 8px 16px;
        background-color: #F5F5F5;
        border: none;
        border-radius: 6px;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        color: #171D21;
        text-align: left;
        cursor: pointer;
        -webkit-user-select: none;
                user-select: none;
        outline: none !important;
        box-shadow: none !important;
    }

    .globalfilter-mobile-button:hover, .globalfilter-mobile-button:active {
        background-color: #F5F5F5 !important;
        color: #171D21 !important;
    }

    .globalfilter-mobile-button .globalfilter-button-caret {
        position: absolute;
        top: 16px;
        right: 16px;
        color: #CCC;
    }

    .globalfilter-label {
        width: 50%;
        display: inline-block;
    }

    .globalfilter-label + .globalfilter-label {
        padding-left: 8px;
    }

    .globalfilter-label p {
        margin-bottom: 0;
        font-weight: bold;
    }

    /* common list styles */
    .map-controls-popover-list li {
        padding: 12px;
    }

    input[type="checkbox"] {
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
    }

    /* search */

    .globalfilter-search-container {
        display: inline-block;
        float: left;
        width: 50%;
        padding: 0 8px 0 0;
    }

    .globalfilter-search-container.mobile-list-search {
        /* search container within GlobalFilterMobileList component */
        float: none;
        width: 100%;
        padding: 0;
        margin-bottom: 16px;
    }

    /* note: these need renaming */
    .map-controls-search-icon {
        left: 12px;
        top: 12px;
    }

    .map-controls-search {
        width: 100%;
        height: 48px;
    }

    /* countries & locations */

    .globalfilter-locations-container {
        display: inline-flex;
        width: 100%;
        padding: 0;
        border: 0;
    }

    .globalfilter-locations-container button:nth-child(2) {
        margin-left: 16px;
    }

    /* calendar */

    .globalfilter-calendar-container {
        display: inline-flex;
        width: 100%;
        padding: 0;
        border: none;
    }

    .globalfilter-calendar-container button {
        width: 50%;
    }

    .globalfilter-calendar-container button:nth-child(2) {
        margin-left: 16px;
    }

    .date-pickers-holder .custom-date-parent button.custom-date {
        width: 30%;
        margin-right: 2px;
        font-size: 0.6rem;
    }

    /* react-datepicker overrides */
    .react-datepicker {
        box-shadow: none;
    }

    /* service types */

    .globalfilter-service-container {
        display: inline-block;
        margin-bottom: 8px;
        padding: 0 0 0 8px;
        border-left: 0;
    }

    .service-toggle-off, .service-toggle-on {
        width: 48px;
        height: 48px;
    }

    .service-toggle-off img, .service-toggle-on img {
        height: 24px;
        width: 24px;
        margin-left: 0;
        margin-top: 0;
    }

}

.mapboxMap{
    height: calc(100vh - 104px);
}

.mapboxgl-popup-content{
    background: #171D21 !important;
    color: #FFFFFF;
    height: 32px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 10px;
    color: #171D21;
    letter-spacing: 0;
    line-height: 24px;
    border-radius: 6px;
}

.mapboxgl-popup-content div{
    color: #FFFFFF;
    margin-top: -6px;
    z-index: 999999;
}

.mapboxgl-popup-tip{
     border-top-color: #171D21 !important;
}

.map-datapanel-mobile{
    display: none;
}

/* --------- data panel ----------------- */

.map-datapanel-show{
    width: 288px;
    height: calc(100vh - 265px);
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14);
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 1040;
    transition: 0.3s;
}

.map-datapanel-hide{
    width: 288px;
    height: calc(100vh - 265px);
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14);
    position: absolute;
    bottom: 16px;
    right: -288px;
    z-index: 1049;
    transition: 0.3s;
}

.map-datapanel-datacontainer{
    border-bottom: 1px solid #F5F5F5;
    padding: 16px;
    display: flex;
}

.map-datapanel-face{
    width: 112px;
    height: 112px;
    margin: 0 auto;
    margin-top: -56px;
}

.map-datapanel-locations{
    width: 100%;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-datapanel-detailcontainer{
    padding: 16px;
    box-sizing: border-box;
    height: calc(100vh - 484px);
    overflow-y: scroll;
}

.map-datapanel-collapse{
    border: 3px solid #F5F5F5;
    border-radius: 6px;
}

.map-datapanel-collapse p{
    padding: 16px;
}

.map-datapanel-collapse .collapse-button{
    width: 100%;
    height: 40px;
    text-align: left;
    background-color: #F5F5F5;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 0px;
    color: #171D21;
    font-size: 12px;
    margin-bottom: 1px;
    position: relative;
}

.collapse-button span{
    padding-left: 32px;
    color: #CCCCCC;
    position: absolute;
    right: 24px;
}

.collapse-button:focus, .collapse-button:active{
    outline: none !important;
    box-shadow: none !important;
    background-color: #F5F5F5 !important;
    margin-bottom: 1px;
}

.daily-breakdown-container{
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 8px;
    display: flex;
    padding-bottom: 16px;
}

.daily-breakdown{
    height: 155px;
    width: 80px;
    margin: 8px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
}

.daily-spacer{
    margin-right: 16px;
}

.daily-container{
    width: 100%;
    height: 133px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 3px solid #F5F5F5;
    position: absolute;
    bottom: 0;
}

.daily-face{
    height: 48px;
    width: 48px;
    margin: 0px auto;
    margin-top: -24px;
}

.daily-face img{
    height: 48px;
    width: 48px;
}

.daily-percent{
    width: 100%;
    text-align: center;
    margin-top: 8px;
}

.daily-delta{
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-top: -4px;
}

.daily-date{
    width: 100%;
    text-align: center;
    font-size: 12px;
    background-color: #F5F5F5;
    position: absolute;
    bottom: 0;
    height: 32px;
    padding-top: 8px;
}

.themebubble-container{
    height: 240px;
    width: 100%;
    padding: 8px;
    cursor: pointer;
}

.themebubbles-modal .modal-content{
    width: 600px !important;
    left: calc(50% - 300px);
}

.themebubbles-modal-container{
    height: 500px;
    width: 100%;
    padding: 8px;
}

svg.theme-bubbles {
    width: 100%;
    height: 100%;
}

svg.theme-bubbles .node--leaf {
    fill: white;
    fill-opacity: 0.25;
}

svg.theme-bubbles .label {
    font: 12px 'Open Sans', sans-serif;
    text-anchor: middle;
}

svg.theme-bubbles .label, svg.theme-bubbles .node--root, svg.theme-bubbles .node--leaf {
    pointer-events: none;
}

svg.theme-bubbles.interactive {
}

svg.theme-bubbles.interactive .node {
    cursor: pointer;
}

svg.theme-bubbles.interactive .node:hover {
    stroke: #C0C0C0;
    stroke-width: 1.5px;
}

.business-container{
    padding: 16px;
}

.business-item{
    width: 100%;
    height: 30px;
}

.business-item-date{
    width: 48px;
    display: inline-block;
}

.business-item-date p{
    margin: 0px;
    padding: 8px;
    text-align: left;
}

.business-item-bar{
    width: calc(100% - 50px);
    display: inline-block;
}

.business-bar-color{
    background: rgba(43,56,65,0.80);
}

.business-bar-bg{
    background-color: #F5F5F5;
}

.business-bar{
    height: 8px;
}

.photo-gallery-modal .modal-content {
    width: 800px !important;
    left: calc(50% - 400px);
}

.photo-gallery-modal-container {
    width: 100%;
    padding: 8px;
}

.gallery-image-container.padded {
    padding: 16px;
}

.gallery-image-container img {
    width: 100%;
    border-radius: 4px;
}

.gallery-image-container.interactive img {
    cursor: pointer;
}













/* 
//////////////////////////////////
// mobile
/////////////////////////////////
*/

/* data panel width for lg screens */
@media only screen and (min-width: 1600px) {

    .map-datapanel-show{
        width: 360px;
    }

    .map-datapanel-hide{
        width: 360px;
        right: -360px;
    }

}

@media only screen and (max-width: 992px) {

    .mapboxMap{
        height: calc(100vh - 300px);
    }

    .mapboxMap button {
       display: none;
    }

    .mapboxgl-ctrl-bottom-left button {
       display: none;
    }

    /* --------- data panel ----------------- */

    .map-datapanel-desktop{
        display: none;
    }

    .map-datapanel-mobile{
        display: block;
        width: 100%;
        background-color: #FFFFFF;
        box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14);
        position: absolute;
        top: calc(100vh - 200px);
        z-index: 1044;
        transition: 0.3s;
    }

    .map-datapanel-detailcontainer-mobile{
        padding: 16px;
        box-sizing: border-box;
    }

}















/*---------- markers ------------- */

.marker-country-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/country-green.7b789c3d.svg);
    cursor: pointer;
}

.marker-country-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/country-red.0a018cdd.svg);
    cursor: pointer;
}

.marker-country-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/country-nodata.ad33be39.svg);
    cursor: pointer;
}

.marker-country-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/country-unselected.20f230f3.svg);
    cursor: pointer;
}

.marker-water-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/water-green.013e53a5.svg);
    cursor: pointer;
}

.marker-water-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/water-red.d4c558f0.svg);
    cursor: pointer;
}

.marker-water-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/water-nodata.58dcc6ce.svg);
    cursor: pointer;
}

.marker-water-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/water-unselected.80ba8e56.svg);
    cursor: pointer;
}

.marker-shelter-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/shelter-green.8cd0182c.svg);
    cursor: pointer;
}

.marker-shelter-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/shelter-red.a8f4f305.svg);
    cursor: pointer;
}

.marker-shelter-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/shelter-nodata.ec667405.svg);
    cursor: pointer;
}

.marker-shelter-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/shelter-unselected.aa647bfc.svg);
    cursor: pointer;
}

.marker-nutrition-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/nutrition-green.46a86c15.svg);
    cursor: pointer;
}

.marker-nutrition-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/nutrition-red.4a6c74c4.svg);
    cursor: pointer;
}

.marker-nutrition-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/nutrition-nodata.d8ebf85e.svg);
    cursor: pointer;
}

.marker-nutrition-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/nutrition-unselected.2b04c461.svg);
    cursor: pointer;
}

.marker-healthcare-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/health-green.2391ce56.svg);
    cursor: pointer;
}

.marker-healthcare-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/health-red.e6c8efbe.svg);
    cursor: pointer;
}

.marker-healthcare-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/health-nodata.db54ff6c.svg);
    cursor: pointer;
}

.marker-healthcare-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/health-unselected.927cae84.svg);
    cursor: pointer;
}

.marker-protection-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/protection-green.b2842c36.svg);
    cursor: pointer;
}

.marker-protection-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/protection-red.cf990ded.svg);
    cursor: pointer;
}

.marker-protection-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/protection-nodata.b39c1e03.svg);
    cursor: pointer;
}

.marker-protection-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/protection-unselected.56f604c6.svg);
    cursor: pointer;
}

.marker-communitypulse-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/pulse-green.d02f38b5.svg);
    cursor: pointer;
}

.marker-communitypulse-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/pulse-red.9887b42c.svg);
    cursor: pointer;
}

.marker-communitypulse-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/pulse-nodata.0cf51329.svg);
    cursor: pointer;
}

.marker-communitypulse-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/pulse-unselected.0ead7be3.svg);
    cursor: pointer;
}

.marker-live-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/live-green.b03f0c62.svg);
    cursor: pointer;
}

.marker-live-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/live-red.7a0eec65.svg);
    cursor: pointer;
}

.marker-live-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/live-nodata.d195202d.svg);
    cursor: pointer;
}

.marker-live-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/live-unselected.faec0ea3.svg);
    cursor: pointer;
}

.marker-generic-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/generic-green.72db652b.svg);
    cursor: pointer;
}

.marker-generic-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/generic-red.865c8590.svg);
    cursor: pointer;
}

.marker-generic-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/generic-nodata.092133e2.svg);
    cursor: pointer;
}

.marker-groupactivities-green{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/groupactivities-green.d888c3ce.svg);
    cursor: pointer;
}

.marker-groupactivities-red{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/groupactivities-red.36cc01a1.svg);
    cursor: pointer;
}

.marker-groupactivities-nodata{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/groupactivities-nodata.7b2da0b1.svg);
    cursor: pointer;
}

.marker-groupactivities-unselected{
    height: 40px;
    width: 30px;
    background-size: cover;
    background-image: url(/static/media/groupactivities-unselected.fa4fb9e7.svg);
    cursor: pointer;
}


.footer{
    width: 100%;
    height: 144px;
    background-color: #2B3841;
    padding: 32px;
    display: flex;
    align-items: center;
}

.footer-details{
    width: 100%;
    height: 64px;
    display: flex;
}

.footer-logo{
    width: 64px;
    height: 64px;
}

.footer-social{
    flex: 1 1;
    display: flex;
    align-items: center;
}

.social-instagram{
    width: 16px;
    height: 16px;
    margin: 16px;
    background-image: url(/static/media/social_instagram_off.fa783128.svg);
    background-size: cover;
    cursor: pointer;
    transition: 0.3s;
    margin-left: auto;
}

.social-instagram:hover{
    background-image: url(/static/media/social_instagram_on.77c23c31.svg);
}

.social-fb{
    width: 16px;
    height: 16px;
    margin: 16px;
    background-image: url(/static/media/social_fb_off.b65a1c67.svg);
    background-size: cover;
    cursor: pointer;
    transition: 0.3s;
}

.social-fb:hover{
    background-image: url(/static/media/social_fb_on.40925c69.svg);
}

.social-twitter{
    width: 18px;
    height: 16px;
    margin: 16px;
    background-image: url(/static/media/social_twitter_off.dc09557b.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    transition: 0.3s;
}

.social-twitter:hover{
    background-image: url(/static/media/social_twitter_on.7fb68fb2.svg);
}

.social-medium{
    width: 16px;
    height: 16px;
    margin: 16px;
    background-image: url(/static/media/social_medium_off.2875e041.svg);
    background-size: cover;
    cursor: pointer;
    transition: 0.3s;
}

.social-medium:hover{
    background-image: url(/static/media/social_medium_on.545287ca.svg);
}
.ideafeed-page{
    background-size: 100% 360px;
}

.ideafeed-page-title{
    margin-top: 60px;
}

.ideafeed-page-title h1{
    margin-left: 0px;
}

.ideafeed-page-bg {
    margin-top: 28px;
    margin-bottom: 56px;
    min-height: 800px;
    width: 100%;
}

.ideafeed-page .globalfilter-container{
    top: 0px;
    margin: 0px;
    position: inherit;
    width: 100%;
    margin-bottom: -16px;
    margin-top: 24px;
}

.ideafeed-page-head{
    width: 100%;
    height: 56px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
}

.ideafeed-results{
    padding-left: 15px;
}

.ideafeed-page-headspacer{
    flex: 1 1;
    flex-grow: 3;
}

.ideafeed-print{
    width: 120px;
    flex: 3 1;
    flex-grow: 1;
    text-align: right;
}

.ideafeed-feed{
    padding: 0px;
    margin: 0px;
}

.ideafeed-item{
    background: #FFFFFF;
    box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
    border-radius: 12px;
    margin-bottom: 20px;
}

.ideafeed-item em {
    background-color: #FFC300;
    margin: 4px;
    border-radius: 4px;
    color: #ffffff;
    padding:3px;
}

.ideafeed-item .small{
    /* background-color: darkseagreen; */
    margin-top: 8px;
    margin-bottom: 6px;
    margin-left: 20px;
}

.ideafeed-item-idea .idea {
    font-size: 20px;
    width: 80%;
}

.ideafeed-item-idea{
    flex: 1 1;
    margin-bottom: 0px;
    padding: 24px;
}

.ideafeed-item-idea button.tag {
    border: none;
    background-color: transparent;
    padding-top: 5px;
    padding-right: 0px;
    padding-left: 5px;
    font-size: 12px;
    color: #2B3841;
}

.ideafeed-item-idea button.tag:hover {
    color: grey;
}

.ideafeed-item-idea button.tag:focus,
.ideafeed-item-idea button.tag:active {
    box-shadow: none !important;
    background-color: transparent !important;
}

.ideafeed-item-details {
    width: 100%;
    min-height: 56px;
    background: #F5F5F5;
    border-radius: 0px 0px 12px 12px;
    padding-top: 1px;
}

.ideafeed-item-details .toggle {
    text-align: center;
    padding: 15px;
    cursor: pointer;
}

.ideafeed-item-details-idea {
    min-height: 141px;
    background: #1C3850;
    border-radius: 8px;
    margin-right: 27px;
    margin-left: 27px;
    margin-top: 26px;
}

.ideafeed-item-details-idea .header {
    height: 56px;
    left: 25px;
    top: 251px;
    background: #122A3E;
    border-radius: 12px 12px 0px 0px;
    padding: 15px;
}

.ideafeed-item-details-idea .header-text {
    font: 14px 'Open Sans', sans-serif;
    color: white;
}

.ideafeed-item-starred {
    width: 40px;
    height: 40px;
    margin-left: auto;
}

.ideafeed-item-starred .icon {
    width: 32px;
    height: 32px;
}

/* pagination styles */

.pagination{
    margin-bottom: 48px;
    margin-left: calc(50% - 175px)

}

.pagination ul{
    display: inline-block;

}

.page-item.active .page-link {
    background-color: #ffc300 !important;
    border-color: #ffc300;
}

.page-link, .page-item .disabled {
    color: #2B3841;
}


@media (max-width: 992px) {

    /* .mobile-container{
        margin: 0px;
        padding: 0px;
    } */

    .ideafeed-page-bg{
        width: 100% !important;
        padding: 0px;
    }

    .ideafeed-page-title h1.left{
        font-size: 24px;
        line-height: 32px;
        text-align: left;
    }

    .ideafeed-page-title p{
        text-align: left;
    }

}

.show-original {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: grey;
}

.show-original-idea {
    font-style: italic;
    font-size: 18px !important;
    font-family: 'Open Sans', sans-serif;
    color: #ffffff;
    padding: 15px;
    line-height: 23px !important;
    width: 80%;
}

.translation-container {
    padding-bottom: 15px;
}

.translation-container .google-badge {
    float: right;
}

.translation-container .text {
    float: left;
}

.satisfaction {
    margin: 0;
    display: flex;
}
.satisfaction-keyword-col {
    flex: 1 1;
    margin-left: 10px;
}

.satisfaction-keywords {
    width: 100%;
    height: 192px;
    background: #1C3850;
    border-radius: 12px;
    display: inline-block;
    height: auto;
    overflow: visible;
}

.satisfaction-keywords .header {
    display: block;
    flex-direction: row;
    min-height: 56px;
    background: #122A3E;
    border-radius: 12px 12px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 14px;
    padding-top: 17px;
    height: auto;
}

.satisfaction-stats {
    width: 265px;
    height: 192px;
    background: #1C3850;
    border-radius: 12px;
    margin-bottom: 20px;
    float: left;
}

.satisfaction-stats .header {
    height: 56px;
    background: #122A3E;
    border-radius: 12px 12px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 24px;
}

.header-toggle {
    color: #b7b6b6;
    padding-right: 30px;
    padding-left: 6px;
    text-align: right;
}

.header-toggle .options {
    cursor: pointer;
    font-size: 13px;
}

.header-toggle .options:hover {
    color: #ffffff;
}

.header-title {
    color: #FFFFFF;
    padding-left: 32px;
}

.selected-keywords {
    font-style: italic;
    font-size: 13px;
    color: #b7b6b6;
    line-height: 2;
}

.keywords-container {
    min-height: 136px;
}

.keywords {
    padding: 15px;
}

.keywords img {
    width: 20px;
    margin-right: 20px;
}

.keyword-option {
    background: #122A3E;
    border-color: #122A3E;
    border-radius: 8px;
    color: #ffffff;
    margin-right: 8px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
    padding: 6px;
}

.keyword-option:hover {
    background: #FFC300;
    color: #ffffff;
}

.keywords-column {
    margin-left: -17px;
}

.top-border {
    border-top: 2px solid #122A3E;
}

.satisfaction-face {
    display: block;
}


.stats {
    display: flex;
}

.stats-pie {
    font-size: 5rem;
    padding-left: 20px;
}

.stats-pie .pie-wrapper .label {
    top: 0.73em;
}

.stats-pie .pie-wrapper .pie-shadow {
    border: 0.07em solid #465C6D;
}

.stats-pie .pie-wrapper.style-2 .label {
    color: #ffffff;
}

.stats-pie .pie-wrapper.style-2 .label .smaller {
    color: #ffffff;
}

.stats-numbers {
    padding: 10px;
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 48px;
    display: block;
    align-items: center;
}

.stats-numbers img {
    width: 20px;
}

.stats-numbers span{
    padding-left: 10px;
}

.no-results {
    color: #b7b6b6;
    font-size: 12px;
    font-style: italic;
    line-height: 15px;
    margin-bottom: 8px;
    padding: 6px;
}

@media (max-width: 1200px) {
    .satisfaction {
        display: block;
        margin-bottom: 20px;
    }

    .satisfaction-keyword-col {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .satisfaction-keywords {
        margin-left: 0px;
        width: 100%;
        margin-right: 15px;
        max-width: unset;
    }

    .keywords-column {
        margin-left: -8px;
    }
    .satisfaction-stats {
        float: right;
    }
}

@media (max-width: 767px) {
    .satisfaction-stats {
        width: 100%;
    }
    .stats{
        justify-content: center;
    }
    .header{
        justify-content: center;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.previous-size {
    font-size: 4em;
    margin-top: 0.3em;
}

.left {
    margin-left: -10px;
}

.right {
    margin-right: -10px;
}

.region-size {
    font-size: 6em;
}

.charts-container:after {
    clear: both;
    content: "";
    display: table;
}

.pie-wrapper {
    height: 1em;
    width: 1em;
    float: left;
    margin: 15px;
    position: relative;
}

.pie-wrapper.large {
    margin-bottom: 2px;
}

.pie-wrapper:nth-child(3n + 1) {
    clear: both;
}

.pie-wrapper .pie {
    height: 100%;
    width: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
    left: 0;
    position: absolute;
    top: 0;
}

.pie-wrapper .pie .half-circle {
    height: 100%;
    width: 100%;
    border: 0.07em solid #00BA54;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0);
    left: 0;
    position: absolute;
    top: 0;
}

.numbers-small-card {
    font-family: 'Open Sans Condensed', 'Arial';
}

.left-right-pie{
    border-width:0.1em !important;
}

.left-right-caption{
    font-size: 22px !important;
    line-height: 1.8em !important;
    padding-left:4px !important;
    left: 0.3em !important;
}

.happy {
    border-color: #00BA54 !important;
}

.unhappy {
    border-color: #F8382B !important;
}

.insignificant {
    border-color: #F5F5F5 !important;
}

.pie-wrapper .label {
    background: #34495e;
    border-radius: 50%;
    bottom: 0.4em;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: 0.25em;
    left: 0.5em;
    line-height: 2.8em;
    position: absolute;
    right: 0.4em;
    text-align: center;
    top: 0.4em;
}

.pie-wrapper .label .smaller {
    color: #bdc3c7;
    font-size: 0.45em;
    padding-bottom: 20px;
    vertical-align: super;
}

.pie-wrapper .label .smaller-responses {
    font-size: 0.37em;
    display: block;
    line-height: 0;
    margin-top: -18px;
}

.pie-wrapper .label .smaller-responses.previous-next {
    margin-top: -5px;
}

.pie-wrapper .pie-shadow {
    height: 100%;
    width: 100%;
    border: 0.07em solid #f5f5f5;
    border-radius: 50%;
}

.pie-wrapper.style-2 .label {
    background: none;
    color: #000000;
}

.pie-wrapper.style-2 .label .smaller {
    color: #000000;
}

.pie-wrapper.progress-1 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-1 .pie .left-side {
    -webkit-transform: rotate(3.6deg);
            transform: rotate(3.6deg);
}

.pie-wrapper.progress-1 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-2 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-2 .pie .left-side {
    -webkit-transform: rotate(7.2deg);
            transform: rotate(7.2deg);
}

.pie-wrapper.progress-2 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-3 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-3 .pie .left-side {
    -webkit-transform: rotate(10.8deg);
            transform: rotate(10.8deg);
}

.pie-wrapper.progress-3 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-4 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-4 .pie .left-side {
    -webkit-transform: rotate(14.4deg);
            transform: rotate(14.4deg);
}

.pie-wrapper.progress-4 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-5 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-5 .pie .left-side {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
}

.pie-wrapper.progress-5 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-6 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-6 .pie .left-side {
    -webkit-transform: rotate(21.6deg);
            transform: rotate(21.6deg);
}

.pie-wrapper.progress-6 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-7 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-7 .pie .left-side {
    -webkit-transform: rotate(25.2deg);
            transform: rotate(25.2deg);
}

.pie-wrapper.progress-7 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-8 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-8 .pie .left-side {
    -webkit-transform: rotate(28.8deg);
            transform: rotate(28.8deg);
}

.pie-wrapper.progress-8 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-9 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-9 .pie .left-side {
    -webkit-transform: rotate(32.4deg);
            transform: rotate(32.4deg);
}

.pie-wrapper.progress-9 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-10 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-10 .pie .left-side {
    -webkit-transform: rotate(36deg);
            transform: rotate(36deg);
}

.pie-wrapper.progress-10 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-11 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-11 .pie .left-side {
    -webkit-transform: rotate(39.6deg);
            transform: rotate(39.6deg);
}

.pie-wrapper.progress-11 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-12 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-12 .pie .left-side {
    -webkit-transform: rotate(43.2deg);
            transform: rotate(43.2deg);
}

.pie-wrapper.progress-12 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-13 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-13 .pie .left-side {
    -webkit-transform: rotate(46.8deg);
            transform: rotate(46.8deg);
}

.pie-wrapper.progress-13 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-14 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-14 .pie .left-side {
    -webkit-transform: rotate(50.4deg);
            transform: rotate(50.4deg);
}

.pie-wrapper.progress-14 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-15 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-15 .pie .left-side {
    -webkit-transform: rotate(54deg);
            transform: rotate(54deg);
}

.pie-wrapper.progress-15 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-16 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-16 .pie .left-side {
    -webkit-transform: rotate(57.6deg);
            transform: rotate(57.6deg);
}

.pie-wrapper.progress-16 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-17 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-17 .pie .left-side {
    -webkit-transform: rotate(61.2deg);
            transform: rotate(61.2deg);
}

.pie-wrapper.progress-17 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-18 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-18 .pie .left-side {
    -webkit-transform: rotate(64.8deg);
            transform: rotate(64.8deg);
}

.pie-wrapper.progress-18 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-19 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-19 .pie .left-side {
    -webkit-transform: rotate(68.4deg);
            transform: rotate(68.4deg);
}

.pie-wrapper.progress-19 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-20 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-20 .pie .left-side {
    -webkit-transform: rotate(72deg);
            transform: rotate(72deg);
}

.pie-wrapper.progress-20 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-21 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-21 .pie .left-side {
    -webkit-transform: rotate(75.6deg);
            transform: rotate(75.6deg);
}

.pie-wrapper.progress-21 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-22 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-22 .pie .left-side {
    -webkit-transform: rotate(79.2deg);
            transform: rotate(79.2deg);
}

.pie-wrapper.progress-22 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-23 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-23 .pie .left-side {
    -webkit-transform: rotate(82.8deg);
            transform: rotate(82.8deg);
}

.pie-wrapper.progress-23 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-24 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-24 .pie .left-side {
    -webkit-transform: rotate(86.4deg);
            transform: rotate(86.4deg);
}

.pie-wrapper.progress-24 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-25 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-25 .pie .left-side {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.pie-wrapper.progress-25 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-26 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-26 .pie .left-side {
    -webkit-transform: rotate(93.6deg);
            transform: rotate(93.6deg);
}

.pie-wrapper.progress-26 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-27 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-27 .pie .left-side {
    -webkit-transform: rotate(97.2deg);
            transform: rotate(97.2deg);
}

.pie-wrapper.progress-27 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-28 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-28 .pie .left-side {
    -webkit-transform: rotate(100.8deg);
            transform: rotate(100.8deg);
}

.pie-wrapper.progress-28 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-29 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-29 .pie .left-side {
    -webkit-transform: rotate(104.4deg);
            transform: rotate(104.4deg);
}

.pie-wrapper.progress-29 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-30 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-30 .pie .left-side {
    -webkit-transform: rotate(108deg);
            transform: rotate(108deg);
}

.pie-wrapper.progress-30 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-31 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-31 .pie .left-side {
    -webkit-transform: rotate(111.6deg);
            transform: rotate(111.6deg);
}

.pie-wrapper.progress-31 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-32 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-32 .pie .left-side {
    -webkit-transform: rotate(115.2deg);
            transform: rotate(115.2deg);
}

.pie-wrapper.progress-32 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-33 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-33 .pie .left-side {
    -webkit-transform: rotate(118.8deg);
            transform: rotate(118.8deg);
}

.pie-wrapper.progress-33 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-34 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-34 .pie .left-side {
    -webkit-transform: rotate(122.4deg);
            transform: rotate(122.4deg);
}

.pie-wrapper.progress-34 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-35 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-35 .pie .left-side {
    -webkit-transform: rotate(126deg);
            transform: rotate(126deg);
}

.pie-wrapper.progress-35 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-36 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-36 .pie .left-side {
    -webkit-transform: rotate(129.6deg);
            transform: rotate(129.6deg);
}

.pie-wrapper.progress-36 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-37 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-37 .pie .left-side {
    -webkit-transform: rotate(133.2deg);
            transform: rotate(133.2deg);
}

.pie-wrapper.progress-37 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-38 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-38 .pie .left-side {
    -webkit-transform: rotate(136.8deg);
            transform: rotate(136.8deg);
}

.pie-wrapper.progress-38 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-39 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-39 .pie .left-side {
    -webkit-transform: rotate(140.4deg);
            transform: rotate(140.4deg);
}

.pie-wrapper.progress-39 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-40 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-40 .pie .left-side {
    -webkit-transform: rotate(144deg);
            transform: rotate(144deg);
}

.pie-wrapper.progress-40 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-41 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-41 .pie .left-side {
    -webkit-transform: rotate(147.6deg);
            transform: rotate(147.6deg);
}

.pie-wrapper.progress-41 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-42 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-42 .pie .left-side {
    -webkit-transform: rotate(151.2deg);
            transform: rotate(151.2deg);
}

.pie-wrapper.progress-42 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-43 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-43 .pie .left-side {
    -webkit-transform: rotate(154.8deg);
            transform: rotate(154.8deg);
}

.pie-wrapper.progress-43 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-44 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-44 .pie .left-side {
    -webkit-transform: rotate(158.4deg);
            transform: rotate(158.4deg);
}

.pie-wrapper.progress-44 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-45 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-45 .pie .left-side {
    -webkit-transform: rotate(162deg);
            transform: rotate(162deg);
}

.pie-wrapper.progress-45 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-46 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-46 .pie .left-side {
    -webkit-transform: rotate(165.6deg);
            transform: rotate(165.6deg);
}

.pie-wrapper.progress-46 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-47 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-47 .pie .left-side {
    -webkit-transform: rotate(169.2deg);
            transform: rotate(169.2deg);
}

.pie-wrapper.progress-47 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-48 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-48 .pie .left-side {
    -webkit-transform: rotate(172.8deg);
            transform: rotate(172.8deg);
}

.pie-wrapper.progress-48 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-49 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-49 .pie .left-side {
    -webkit-transform: rotate(176.4deg);
            transform: rotate(176.4deg);
}

.pie-wrapper.progress-49 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-50 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-50 .pie .left-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-50 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-51 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-51 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-51 .pie .left-side {
    -webkit-transform: rotate(183.6deg);
            transform: rotate(183.6deg);
}

.pie-wrapper.progress-51 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-52 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-52 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-52 .pie .left-side {
    -webkit-transform: rotate(187.2deg);
            transform: rotate(187.2deg);
}

.pie-wrapper.progress-52 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-53 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-53 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-53 .pie .left-side {
    -webkit-transform: rotate(190.8deg);
            transform: rotate(190.8deg);
}

.pie-wrapper.progress-53 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-54 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-54 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-54 .pie .left-side {
    -webkit-transform: rotate(194.4deg);
            transform: rotate(194.4deg);
}

.pie-wrapper.progress-54 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-55 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-55 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-55 .pie .left-side {
    -webkit-transform: rotate(198deg);
            transform: rotate(198deg);
}

.pie-wrapper.progress-55 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-56 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-56 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-56 .pie .left-side {
    -webkit-transform: rotate(201.6deg);
            transform: rotate(201.6deg);
}

.pie-wrapper.progress-56 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-57 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-57 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-57 .pie .left-side {
    -webkit-transform: rotate(205.2deg);
            transform: rotate(205.2deg);
}

.pie-wrapper.progress-57 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-58 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-58 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-58 .pie .left-side {
    -webkit-transform: rotate(208.8deg);
            transform: rotate(208.8deg);
}

.pie-wrapper.progress-58 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-59 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-59 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-59 .pie .left-side {
    -webkit-transform: rotate(212.4deg);
            transform: rotate(212.4deg);
}

.pie-wrapper.progress-59 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-60 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-60 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-60 .pie .left-side {
    -webkit-transform: rotate(216deg);
            transform: rotate(216deg);
}

.pie-wrapper.progress-60 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-61 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-61 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-61 .pie .left-side {
    -webkit-transform: rotate(219.6deg);
            transform: rotate(219.6deg);
}

.pie-wrapper.progress-61 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-62 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-62 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-62 .pie .left-side {
    -webkit-transform: rotate(223.2deg);
            transform: rotate(223.2deg);
}

.pie-wrapper.progress-62 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-63 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-63 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-63 .pie .left-side {
    -webkit-transform: rotate(226.8deg);
            transform: rotate(226.8deg);
}

.pie-wrapper.progress-63 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-64 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-64 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-64 .pie .left-side {
    -webkit-transform: rotate(230.4deg);
            transform: rotate(230.4deg);
}

.pie-wrapper.progress-64 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-65 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-65 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-65 .pie .left-side {
    -webkit-transform: rotate(234deg);
            transform: rotate(234deg);
}

.pie-wrapper.progress-65 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-66 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-66 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-66 .pie .left-side {
    -webkit-transform: rotate(237.6deg);
            transform: rotate(237.6deg);
}

.pie-wrapper.progress-66 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-67 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-67 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-67 .pie .left-side {
    -webkit-transform: rotate(241.2deg);
            transform: rotate(241.2deg);
}

.pie-wrapper.progress-67 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-68 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-68 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-68 .pie .left-side {
    -webkit-transform: rotate(244.8deg);
            transform: rotate(244.8deg);
}

.pie-wrapper.progress-68 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-69 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-69 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-69 .pie .left-side {
    -webkit-transform: rotate(248.4deg);
            transform: rotate(248.4deg);
}

.pie-wrapper.progress-69 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-70 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-70 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-70 .pie .left-side {
    -webkit-transform: rotate(252deg);
            transform: rotate(252deg);
}

.pie-wrapper.progress-70 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-71 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-71 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-71 .pie .left-side {
    -webkit-transform: rotate(255.6deg);
            transform: rotate(255.6deg);
}

.pie-wrapper.progress-71 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-72 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-72 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-72 .pie .left-side {
    -webkit-transform: rotate(259.2deg);
            transform: rotate(259.2deg);
}

.pie-wrapper.progress-72 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-73 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-73 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-73 .pie .left-side {
    -webkit-transform: rotate(262.8deg);
            transform: rotate(262.8deg);
}

.pie-wrapper.progress-73 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-74 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-74 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-74 .pie .left-side {
    -webkit-transform: rotate(266.4deg);
            transform: rotate(266.4deg);
}

.pie-wrapper.progress-74 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-75 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-75 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-75 .pie .left-side {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}

.pie-wrapper.progress-75 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-76 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-76 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-76 .pie .left-side {
    -webkit-transform: rotate(273.6deg);
            transform: rotate(273.6deg);
}

.pie-wrapper.progress-76 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-77 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-77 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-77 .pie .left-side {
    -webkit-transform: rotate(277.2deg);
            transform: rotate(277.2deg);
}

.pie-wrapper.progress-77 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-78 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-78 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-78 .pie .left-side {
    -webkit-transform: rotate(280.8deg);
            transform: rotate(280.8deg);
}

.pie-wrapper.progress-78 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-79 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-79 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-79 .pie .left-side {
    -webkit-transform: rotate(284.4deg);
            transform: rotate(284.4deg);
}

.pie-wrapper.progress-79 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-80 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-80 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-80 .pie .left-side {
    -webkit-transform: rotate(288deg);
            transform: rotate(288deg);
}

.pie-wrapper.progress-80 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-81 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-81 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-81 .pie .left-side {
    -webkit-transform: rotate(291.6deg);
            transform: rotate(291.6deg);
}

.pie-wrapper.progress-81 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-82 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-82 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-82 .pie .left-side {
    -webkit-transform: rotate(295.2deg);
            transform: rotate(295.2deg);
}

.pie-wrapper.progress-82 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-83 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-83 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-83 .pie .left-side {
    -webkit-transform: rotate(298.8deg);
            transform: rotate(298.8deg);
}

.pie-wrapper.progress-83 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-84 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-84 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-84 .pie .left-side {
    -webkit-transform: rotate(302.4deg);
            transform: rotate(302.4deg);
}

.pie-wrapper.progress-84 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-85 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-85 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-85 .pie .left-side {
    -webkit-transform: rotate(306deg);
            transform: rotate(306deg);
}

.pie-wrapper.progress-85 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-86 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-86 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-86 .pie .left-side {
    -webkit-transform: rotate(309.6deg);
            transform: rotate(309.6deg);
}

.pie-wrapper.progress-86 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-87 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-87 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-87 .pie .left-side {
    -webkit-transform: rotate(313.2deg);
            transform: rotate(313.2deg);
}

.pie-wrapper.progress-87 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-88 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-88 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-88 .pie .left-side {
    -webkit-transform: rotate(316.8deg);
            transform: rotate(316.8deg);
}

.pie-wrapper.progress-88 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-89 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-89 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-89 .pie .left-side {
    -webkit-transform: rotate(320.4deg);
            transform: rotate(320.4deg);
}

.pie-wrapper.progress-89 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-90 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-90 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-90 .pie .left-side {
    -webkit-transform: rotate(324deg);
            transform: rotate(324deg);
}

.pie-wrapper.progress-90 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-91 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-91 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-91 .pie .left-side {
    -webkit-transform: rotate(327.6deg);
            transform: rotate(327.6deg);
}

.pie-wrapper.progress-91 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-92 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-92 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-92 .pie .left-side {
    -webkit-transform: rotate(331.2deg);
            transform: rotate(331.2deg);
}

.pie-wrapper.progress-92 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-93 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-93 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-93 .pie .left-side {
    -webkit-transform: rotate(334.8deg);
            transform: rotate(334.8deg);
}

.pie-wrapper.progress-93 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-94 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-94 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-94 .pie .left-side {
    -webkit-transform: rotate(338.4deg);
            transform: rotate(338.4deg);
}

.pie-wrapper.progress-94 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-95 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-95 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-95 .pie .left-side {
    -webkit-transform: rotate(342deg);
            transform: rotate(342deg);
}

.pie-wrapper.progress-95 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-96 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-96 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-96 .pie .left-side {
    -webkit-transform: rotate(345.6deg);
            transform: rotate(345.6deg);
}

.pie-wrapper.progress-96 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-97 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-97 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-97 .pie .left-side {
    -webkit-transform: rotate(349.2deg);
            transform: rotate(349.2deg);
}

.pie-wrapper.progress-97 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-98 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-98 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-98 .pie .left-side {
    -webkit-transform: rotate(352.8deg);
            transform: rotate(352.8deg);
}

.pie-wrapper.progress-98 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-99 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-99 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-99 .pie .left-side {
    -webkit-transform: rotate(356.4deg);
            transform: rotate(356.4deg);
}

.pie-wrapper.progress-99 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper.progress-100 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-100 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-100 .pie .left-side {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.pie-wrapper.progress-100 .pie .right-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.pie-wrapper--solid {
    border-radius: 50%;
    overflow: hidden;
}

.pie-wrapper--solid:before {
    border-radius: 0 100% 100% 0/50%;
    content: "";
    display: block;
    height: 100%;
    margin-left: 50%;
    -webkit-transform-origin: left;
            transform-origin: left;
}

.pie-wrapper--solid .label {
    background: transparent;
}

.pie-wrapper--solid.progress-65 {
    background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}

.pie-wrapper--solid.progress-65:before {
    background: #e67e22;
    -webkit-transform: rotate(126deg);
            transform: rotate(126deg);
}

.pie-wrapper--solid.progress-25 {
    background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}

.pie-wrapper--solid.progress-25:before {
    background: #34495e;
    -webkit-transform: rotate(-270deg);
            transform: rotate(-270deg);
}

.pie-wrapper--solid.progress-88 {
    background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}

.pie-wrapper--solid.progress-88:before {
    background: #3498db;
    -webkit-transform: rotate(43.2deg);
            transform: rotate(43.2deg);
}


.snapshots-page {
    background-size: 100% 360px;
}

.snapshots-page-title{
    margin-top: 60px;
}

.snapshots-page-title h1{
    margin-left: 0px;
}
.snapshots-page h3 {
    font-family: 'Nunito Sans', 'Arial';
}

.snapshots-page p {
    font-family: 'Arial';
}

.snapshots-header-title {
    margin-top: 50px;
    width: 100%;
    text-align: center;
}

.snapshots-page-bg {
    margin-top: 20px;
    margin-bottom: 56px;
    min-height: 800px;
    width: 100%;
}

.cards-parent {
    margin-left: -20px;
    margin-right: -25px;
}

.snapshots-page .globalfilter-container {
    top: 0px;
    margin: 0px;
    position: inherit;
    width: 100%;
    margin-bottom: -16px;
    margin-top: 24px;
}

.snapshots-page-head {
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #F5F5F5;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
}

.snapshots-results {
    /* width: 120px; */
    flex: 1 1;
    flex-grow: 2;
    padding-left: 8px;
}

.snapshots-page-headspacer {
    flex: 1 1;
    flex-grow: 3;
}

.snapshots-card {
    border-radius: 5px;
    width: 360px;
    height: 650px;
    max-width: 360px;
    min-width: 360px;
    margin: 12px 6px 6px 6px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    box-shadow: 0 9px 12px -6px rgba(23, 29, 33, 0.14)
}

.snapshots-card.downloading {
    box-shadow: none;
    margin: 0;
}

.snapshots-card.hidden {
    display: none;
}

.snapshots-card button.download.hidden {
    display: none;
}

.snapshots-card button.no-text-transform {
    text-transform: none;
}

.snapshots-card div.title {
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 20px 10px 20px;
    font-size: 1em;
    margin-right: -16px;
    margin-left: -16px;
    margin-top: -1px;
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-family: 'Nunito Sans', 'Arial';
    font-weight: bold;
}

.snapshots-card.fixed-height{
    height: 670px;
}
.snapshots-card.auto-height{
    height: auto;
}

.snapshots-card p.subtitle {
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
}

.snapshots-card p.subtitle span.hidden {
    display: none;
}

.snapshots-card div.featured-idea {
    border: 1px solid #FFC300;
    margin-top: 15px;
    border-radius: 3px;
    padding: 5px;
    height: 150px;
    background-color: #FFFBEA;
}

.snapshots-card div.featured-idea h3 {
    text-align: left;
    background-color: #FFC300;
    margin: -5px;
    padding: 3px 12px;
    font-size: 0.8em;
    font-weight: 400;
}

.snapshots-card div.featured-idea p {
    font-size: 0.85em;
    line-height: 1.65;
    padding-top: 12px;
    padding-left: 6px;
    padding-right: 6px;
}

.snapshots-card div.featured-idea p.no-featured-idea {
    font-style:italic;
    text-align: center;
    font-size: 0.75em;
}

.snapshots-card div.service-types {
    border: 1px solid #F5F5F5;
    margin-top: 15px;
    border-radius: 3px;
    padding: 5px;
    height: 170px;
    min-height: 170px;
    max-height: 170px;
    background-color: #FFFFFF;
    overflow: hidden;
}

.snapshots-card div.service-types-all {
    border: 1px solid #F5F5F5;
    margin-top: 15px;
    border-radius: 3px;
    padding: 5px;
    background-color: #FFFFFF;
}

.snapshots-card div.service-types h3 {
    text-align: left;
    background-color: #F5F5F5;
    margin: -5px;
    padding: 3px 12px;
    font-size: 0.75rem;
    font-weight: 200;
}

.snapshots-card div.service-types-all h3 {
    text-align: left;
    background-color: #F5F5F5;
    margin: -5px;
    padding: 3px 12px;
    font-size: 0.8rem;
    font-weight: 400;
}

.snapshots-card div.service-types p {
    font-size: 0.85rem;
    line-height: 1.65;
    padding-top: 10px;
}

.snapshots-card p.previous-next {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 0.7rem;
    color: #171717;
    line-height: 1.3;
}

.snapshots-card p.current-delta {
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -14px;
    font-size: 0.53rem;
    font-weight: bold;
}

.no-delta-difference{
    color: #888888;
    opacity: 0.5;
}

.printing{
    display: none;
}

.service-type-container {
    font-size: 14px;
}

.service-type-container div {
    font-size: 0.8rem;
    color: #4a4a4a;
    line-height: 3.2;
}

.service-type-container div.col-2.icon {
    text-align: right;
}

.service-type-container img.service-type-icon {
    max-width:20px;
}

.service-type-container div.service-title {
    font-weight: 300;
    font-size: 0.9em;
    margin-top: 4px;
}

.service-type-container div.summary-circle {
    margin-top: 14px;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin-right: 3px;
    float: left;
}

.service-type-container .delta-icon {
    font-size: 0.5rem;
    float: left;
}

.service-type-container span.average {
    font-size: 0.9rem;
    font-family: 'Open Sans Condensed', 'Arial';
    font-weight: bold;
}

.service-type-container span.average.insignificant {
    color: #888A8E;
    font-weight: normal;
}

.service-type-container div.delta {
    margin-right: 6px;
    float: right;
    font-size: 0.7rem;
    font-family: 'Open Sans Condensed', 'Arial';
    font-weight: bold;
}

.service-point-highest-lowest {
    font-weight: bold;
    color: #222222;
    font-size: 14px;
}

button.show-details {
    margin: 15px auto 5px auto;
    width: 100%;
    font-size: 0.9rem;
    background-color: #000000;
    text-transform: capitalize;
}

button.download {
    width: 100%;
    font-size: 0.75rem;
    color: #000000;
}

.highest-lowest-service-point {
    border-radius: 3px;
    margin-top: 12px;
}

.highest-service-point {
    border: 2px solid #00BA54;
}

.lowest-service-point {
    border: 2px solid #F8382B;
}

.highest-lowest-service-point div.average {

    flex-direction: column;
    justify-content: center;

    text-align: center;
    font-size: 1.4rem;
    color: #484848;
    width: 55px;
    max-width: 55px;
    min-width: 55px;
    font-family: 'Open Sans Condensed', 'Arial';
}

.highest-service-point div.average {
    background-color: #00BA54;
}

.lowest-service-point div.average {
    background-color: #F8382B;
}

.highest-lowest-service-point div.average span.percent {
    font-size: 0.6rem;
}

.highest-lowest-service-point div.name {
    font-size: 0.85rem;
    color: #484848;
    width: 100%;
}

.highest-service-point div.name {
    background-color: #EBF9F1;
}

.lowest-service-point div.name {
    background-color: #FFEEED;
}

.highest-lowest-service-point div.name div.explanation {
    font-size: 0.73rem;
    marign-left: 2px;
}

.snapshots-card div.service-points-parent {
    margin: 0;
    padding: 0;
}

.snapshots-card div.service-points-parent h3 {
    text-align: left;
    background-color: #F5F5F5;
    padding: 3px 12px;
    font-size: 0.8rem;
    font-weight: 300;
    margin: 12px 0 0 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 3px;
}

.snapshots-card div.service-points {
    border: 1px solid #F5F5F5;
    margin-top: 0px;
    border-radius: 0 0 3px 3px;
    padding: 5px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-flow: row wrap;
}

.service-point-list-item {

    padding: 6px;
    float: left;
    margin: 3px;
    background-color: #F5F5F5;
    font-size: 0.55rem;
    border-radius: 2px;
}

.service-point-list-item span {
    line-height: 3;
    margin-left: 5px;
}

.service-point-list-item div.summary-circle {
    margin-top: 5px;
    float: left;
    border-radius: 50%;
    height: 16px;
    width: 16px;
}

@media (max-width: 992px) {

    /* .mobile-container{
        margin: 0px;
        padding: 0px;
    } */
    .snapshots-page-bg {
        width: 100% !important;
        padding: 0px;
    }

    .snapshots-page-title h1.left {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
    }

    .snapshots-page-title p {
        text-align: left;
    }

    .snapshots-card {
        width: 345px;
        max-width: 345px;
        min-width: 345px;
    }

}

.google-badge {
    display: flex;
}

.google-badge .text {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: grey;
    padding-top: 4px;
}

.google-badge .badge {
    float: right;
    margin-left: auto;
    padding-top: 7px;
}

.privacy-policy-title {
    margin-top: 136px;
}

.privacy-policy-title h1 {
    margin-bottom: 30px;
}

.privacy-policy p {
    font-family: 'Arial';
}
.no-page {
    text-align: center;
    min-height: calc(100vh - 248px);
}

.no-page-container {
    width: 100%;
    top: 145px;
    background: #FFFFFF;
    box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
    border-radius: 12px;
    margin-top: 145px;
}

.no-page-content {
    display: block;
    padding-top: 50px;
    padding-right: 46px;
    padding-left: 48px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.no-page-buttons {
    width: 100%;
    height: 56px;
    top: 251px;
    background: #F5F5F5;
    border-radius: 0px 0px 12px 12px;
}

.no-page-option {
    padding-top: 16px;
}

.no-page-back {
    cursor: pointer;
}

a, a:hover {
    color: black;
    text-decoration: none;
}

.no-page-icon {
    width: 73px;
    height: 73px;
    position: absolute;
    top: 106px;
    left: calc(50% - 36px);
    border-radius: 50%;
}

.no-page-icon img {
    height: 92px;
    position: relative;
    top: -8px;
}


.login-page{
    background-size: 100% 400px !important;
    background-repeat: no-repeat;
    min-height: calc(100vh - 144px);
}

.login-page h3{
    margin-top: 0px;
    margin-bottom: 16px;
}

.login-page input {
    padding: 16px;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 0px !important;
    margin-bottom: 16px;
    width: 100%;
}

.login-page button{
    width: 100%;
}

.login-page .login-form{
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 24px 18px -10px rgba(23,29,33,0.08);
    padding: 24px;
    position: absolute;
    top: 50%;
    margin-top: -116px;
    /*height: 288px;*/
}

.login-form .alert {
    margin-top: 16px;
    margin-bottom: 0 !important;
}

.login-row{
    height: calc(100vh - 144px);
}
 /* ----------- Loading ------------------*/

 /* .loading {
    background-color: #171D21;
    margin: 0 auto;
    width: 64px;
    text-align: center;
    position: fixed;
    z-index: 999999;
    bottom: 16px;
    left: calc(50% - 32px);
    border-radius: 24px;
    box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14);
    transition: 0.3s;
    opacity: 1;
}

.loading > div {
    width: 4px;
    height: 4px;
    background-color: #FFFFFF;
    padding: 8px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin-top: 8px;
}

.loading .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loading .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
    -webkit-transform: scale(1.0);
        transform: scale(0.6);
    }
} */

.loading-container{
    background-color: #171D21;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    text-align: center;
    position: fixed;
    z-index: 999999;
    /* bottom: calc(50% - 32px); */
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    border-radius: 8px;
    /* box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14); */
    transition: 0.3s;
    opacity: 1;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
}

.loading-overlay{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2000;
    cursor: wait;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.loader {
  margin: 15px auto;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.action-header {
    background-color: #fcc300;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: -20px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.actions-header-desc {
    margin-bottom: 80px;
    margin-right: 40%;
}

.action-header h1 {
    font-weight: 600;
    font-size: 40px;
}

.action-header h4 {
    margin-left: 40%;
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 400;
    font-size: 20px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
}

h1 {
    text-align: center;
    margin: 20px auto;
}

.action-card-parent {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto;
}

.action-card {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    margin: 15px auto;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 24px 18px -10px rgba(23, 29, 33, .08);
}

.action-card .show-original-idea {	
    font-style: italic;	
    font-size: 15px !important;	
    font-family: 'Open Sans', sans-serif;	
    color: #ffffff;	
    padding: 15px;	
    line-height: 23px !important;	
    width: 100%;	
}	

.action-card .title {	
    padding-left: 15px;	
    padding-right: 15px;	
    padding-top: 8px;	
    padding-bottom: 0px;	
    font-weight: 900;	
}	

.action-card .description {	
    padding-top: 0px;	
}	

.action-card .ideafeed-item-details {	
    border-radius: 0;	
}	

.action-card .translation-container {	
    padding-left: 15px;	
    padding-right: 15px;	
}	

.action-card em {	
    background-color: #ffc300;	
    margin: 4px;	
    border-radius: 4px;	
    color: #fff;	
    padding: 3px;	
}	

.action-card .card {	
    margin: 10px;	
}

.action-top-details {
    display: flex;
    flex-direction: column;
}

.action-card-body {
    margin-top: 15px;
    width: auto;
    margin-right: 15px;
    text-align: justify;
}

.action-img {
    /* width: 100%; */
    /* padding: 10px 10px;
    border-color: #fff; */
    border: none;
}

.action-img img {
    /* width: 120%; */
    /* height: 266px;
    border-color: none; */
    border-radius: 12px;
}

a {
    width: auto;
    /* margin-right: 30px; */
}

.action-links:hover .action-source:hover {
    margin-right: 10px;
    color: #757575;
}

.action-icon:hover {
    color: #757575;
    margin-right: 5px;
}

.footer-text:hover {
    padding-right: 5px;
}

.footer-text {	
    font-size: 15px;	
}

.action-icon {
    margin-right: 20px;
}

.action-source {
    margin-right: 5px;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-weight: bolder;
}

.action-rate {
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 300;
    font-size: 18px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 25px;
}

.action-details {
    margin-top: -20px;
    margin-left: 50px;
    /* margin-right: -28px; */
}

.action-desc {
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 300;
    font-size: 15px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
}

.action-title {
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 300;
    font-size: 18px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
    /* margin-right: 30%; */
    margin-bottom: -20px;
    text-align: left;
}

.action-author {
    font-family: 'Open Sans', sans-serif;
    ;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    color: #757575;
    letter-spacing: 0;
    line-height: 24px;
}

.action-content {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.action-time,
.action-sector,
.action-area,
.action-tag {
    margin-right: 8px;
    font-size: 11px;
    color: #757575;
}

.action-bottom-details {
    background-color: #eee;
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
}

.action-footer {
    width: 100%;
    min-height: 56px;
    background: #F5F5F5;
    border-radius: 0px 0px 12px 12px;
    padding-top: 14px;
}

.footer-desc {
    padding-left: 12px;
}

.footer-desc p {	
    font-size: 15px;
}

.action-links {
    text-align: end;
}

.pageGo {
    padding: 10px;
    color: #F5F5F5;
    background-color: #F5F5F5;
    float: right;
    margin-top: 80px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    padding: 9px 30px;
}

@media only screen and (max-width: 600px) {
    .action-card {
        padding: 0px;
        /* height: 65%; */
        width: 100%;
        flex-direction: column;
    }
    .action-top-details {
        flex-direction: column;
    }
    .action-card-body {
        /* flex-direction: column;
        margin-left: -110px;
        height: 10%;
        margin-top: 280px;
        width: 150%; */
        margin-left: 15px;
    }
    .action-content {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin-left: 5 5px;
    }
    .action-time,
    .action-sector,
    .action-area {
        margin-right: 4px;
    }
    .action-sector {
        margin-top: -15px;
    }
    .action-footer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-right: 24px;	
        padding-left: 24px;
    }
    .footer-desc {
        text-align: center;
        margin-top: -15px;
        margin-left: -10px;
        width: 130%;
    }
    .action-links {
        /* margin-top: 70px;
        margin-left: -160px; */
        margin-bottom: 10px;
    }
}

/* @media only screen and (max-width: 400px) {
    .action-card {
        height: 65%;
        width: 80%;
        flex-direction: column;
    }
} */

.no-actions {
    text-align: center;
    font-style: italic;
}

.page-title {
    position: relative;
}

.page-title button {
    position: absolute;
    right: 0;
    margin-top: 70px;
}

.action-preview {
    border: 1px solid #F5F5F5;
}

 /* body {
     margin: 0;
     padding: 0;
     background-color: #f5f5f5;
     font-family: "Open Sans", sans-serif;
 } */
 
 .page-container {
     overflow: hidden;
 }
 
 .page-top {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-evenly;
     margin-top: -96px;
     overflow: hidden;
     background-color: #ffc300;
     width: 120%;
     height: 400px;
     padding: 0 20%;
     border-bottom-left-radius: 50%;
     border-bottom-right-radius: 50%;
 }
 
 .pageDescription {
     text-align: left;
 }
 
 .page-top h1 {
     font-family: "Nunito Sans";
     font-size: 40px;
     font-weight: 900;
     letter-spacing: 0;
     line-height: 48px;
     margin-right: 80px;
 }
 
 .pageDescription h4 {
     font-family: "Nunito Sans";
     font-size: 20px;
     font-weight: 100;
     letter-spacing: 0;
     line-height: 15px;
     margin-left: 125px;
     text-transform: capitalize;
 }
 
 .pageReturn {
     text-align: end;
     padding-top: 40px;
}

@media only screen and (max-width: 768px) and (min-width: 359px) {
    .pageReturn {
        padding-top: 70px;
    }
}
 
 .card-1 {
     background-color: #f5f5f5;
     border-radius: 10px;
     margin-left: -14px;
     margin-bottom: 17px;
     padding: 16px 14px 1px 14px;
     box-shadow: 0px 8px 12px rgb(23 29 33 / 14%);
 }
 
 .card-1 h4 {
     text-align: left;
     text-transform: capitalize;
 }
 
 .card-1 select {
     border: 1px solid #fff;
     border-radius: 4px;
     box-sizing: border-box;
     margin-top: 20px;
     margin-bottom: 16px;
     resize: vertical;
     width: auto;
     height: 41px;
     margin-right: 10px;
     padding-left: 20px;
 }
 
 input[type=file] {
     font-size: 12px;
 }
 
 .image-upload {
     width: 70%;
     height: 30%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     margin-left: 14%;
     margin-top: 10px;
     margin-bottom: 20px;
     background-color: #F7EAC5;
 }
 
 .card-1 img {
     width: 40%;
     margin-top: 40px;
     margin-left: 40px;
 }
 
 .second-icons {
     width: 100%;
     height: 20%;
     display: flex;
     flex-direction: row;
     /* border: 1px solid #ccc; */
     border-radius: 4px;
     /* padding-left: 9px; */
 }
 
 .second-icons img {
     width: 60%;
     height: 50%;
 }
 
 .second-icons>div {
     border: 1px solid #Fcc360;
     border-radius: 4px;
     display: flex;
     flex-direction: column;
     background-color: #F7EAC5;
     margin: 8px 8px 8px;
 }
 
 .second-icons h6 {
     margin-top: -45px;
     margin-left: 30px;
 }
 
 button[type=submit] {
     margin-top: 16px;
 }
 
 form {
     border-radius: 5px;
     background-color: #fff;
     width: 100%;
     margin-left: -5px;
     padding-left: 35px;
     padding-top: 20px;
     padding-right: 22px;
     padding-bottom: 15px;
     margin-bottom: 30px;
 }

 .form-error {
     color: #dc3545 !important;
 }

