
.snapshots-page {
    background-size: 100% 360px;
}

.snapshots-page-title{
    margin-top: 60px;
}

.snapshots-page-title h1{
    margin-left: 0px;
}
.snapshots-page h3 {
    font-family: 'Nunito Sans', 'Arial';
}

.snapshots-page p {
    font-family: 'Arial';
}

.snapshots-header-title {
    margin-top: 50px;
    width: 100%;
    text-align: center;
}

.snapshots-page-bg {
    margin-top: 20px;
    margin-bottom: 56px;
    min-height: 800px;
    width: 100%;
}

.cards-parent {
    margin-left: -20px;
    margin-right: -25px;
}

.snapshots-page .globalfilter-container {
    top: 0px;
    margin: 0px;
    position: inherit;
    width: 100%;
    margin-bottom: -16px;
    margin-top: 24px;
}

.snapshots-page-head {
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #F5F5F5;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
}

.snapshots-results {
    /* width: 120px; */
    flex: 1;
    flex-grow: 2;
    padding-left: 8px;
}

.snapshots-page-headspacer {
    flex: 1;
    flex-grow: 3;
}

.snapshots-card {
    border-radius: 5px;
    width: 360px;
    height: 650px;
    max-width: 360px;
    min-width: 360px;
    margin: 12px 6px 6px 6px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 9px 12px -6px rgba(23, 29, 33, 0.14);
    box-shadow: 0 9px 12px -6px rgba(23, 29, 33, 0.14)
}

.snapshots-card.downloading {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
}

.snapshots-card.hidden {
    display: none;
}

.snapshots-card button.download.hidden {
    display: none;
}

.snapshots-card button.no-text-transform {
    text-transform: none;
}

.snapshots-card div.title {
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 20px 10px 20px;
    font-size: 1em;
    margin-right: -16px;
    margin-left: -16px;
    margin-top: -1px;
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-family: 'Nunito Sans', 'Arial';
    font-weight: bold;
}

.snapshots-card.fixed-height{
    height: 670px;
}
.snapshots-card.auto-height{
    height: auto;
}

.snapshots-card p.subtitle {
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
}

.snapshots-card p.subtitle span.hidden {
    display: none;
}

.snapshots-card div.featured-idea {
    border: 1px solid #FFC300;
    margin-top: 15px;
    border-radius: 3px;
    padding: 5px;
    height: 150px;
    background-color: #FFFBEA;
}

.snapshots-card div.featured-idea h3 {
    text-align: left;
    background-color: #FFC300;
    margin: -5px;
    padding: 3px 12px;
    font-size: 0.8em;
    font-weight: 400;
}

.snapshots-card div.featured-idea p {
    font-size: 0.85em;
    line-height: 1.65;
    padding-top: 12px;
    padding-left: 6px;
    padding-right: 6px;
}

.snapshots-card div.featured-idea p.no-featured-idea {
    font-style:italic;
    text-align: center;
    font-size: 0.75em;
}

.snapshots-card div.service-types {
    border: 1px solid #F5F5F5;
    margin-top: 15px;
    border-radius: 3px;
    padding: 5px;
    height: 170px;
    min-height: 170px;
    max-height: 170px;
    background-color: #FFFFFF;
    overflow: hidden;
}

.snapshots-card div.service-types-all {
    border: 1px solid #F5F5F5;
    margin-top: 15px;
    border-radius: 3px;
    padding: 5px;
    background-color: #FFFFFF;
}

.snapshots-card div.service-types h3 {
    text-align: left;
    background-color: #F5F5F5;
    margin: -5px;
    padding: 3px 12px;
    font-size: 0.75rem;
    font-weight: 200;
}

.snapshots-card div.service-types-all h3 {
    text-align: left;
    background-color: #F5F5F5;
    margin: -5px;
    padding: 3px 12px;
    font-size: 0.8rem;
    font-weight: 400;
}

.snapshots-card div.service-types p {
    font-size: 0.85rem;
    line-height: 1.65;
    padding-top: 10px;
}

.snapshots-card p.previous-next {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 0.7rem;
    color: #171717;
    line-height: 1.3;
}

.snapshots-card p.current-delta {
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -14px;
    font-size: 0.53rem;
    font-weight: bold;
}

.no-delta-difference{
    color: #888888;
    opacity: 0.5;
}

.printing{
    display: none;
}

.service-type-container {
    font-size: 14px;
}

.service-type-container div {
    font-size: 0.8rem;
    color: #4a4a4a;
    line-height: 3.2;
}

.service-type-container div.col-2.icon {
    text-align: right;
}

.service-type-container img.service-type-icon {
    max-width:20px;
}

.service-type-container div.service-title {
    font-weight: 300;
    font-size: 0.9em;
    margin-top: 4px;
}

.service-type-container div.summary-circle {
    margin-top: 14px;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin-right: 3px;
    float: left;
}

.service-type-container .delta-icon {
    font-size: 0.5rem;
    float: left;
}

.service-type-container span.average {
    font-size: 0.9rem;
    font-family: 'Open Sans Condensed', 'Arial';
    font-weight: bold;
}

.service-type-container span.average.insignificant {
    color: #888A8E;
    font-weight: normal;
}

.service-type-container div.delta {
    margin-right: 6px;
    float: right;
    font-size: 0.7rem;
    font-family: 'Open Sans Condensed', 'Arial';
    font-weight: bold;
}

.service-point-highest-lowest {
    font-weight: bold;
    color: #222222;
    font-size: 14px;
}

button.show-details {
    margin: 15px auto 5px auto;
    width: 100%;
    font-size: 0.9rem;
    background-color: #000000;
    text-transform: capitalize;
}

button.download {
    width: 100%;
    font-size: 0.75rem;
    color: #000000;
}

.highest-lowest-service-point {
    border-radius: 3px;
    margin-top: 12px;
}

.highest-service-point {
    border: 2px solid #00BA54;
}

.lowest-service-point {
    border: 2px solid #F8382B;
}

.highest-lowest-service-point div.average {

    flex-direction: column;
    justify-content: center;

    text-align: center;
    font-size: 1.4rem;
    color: #484848;
    width: 55px;
    max-width: 55px;
    min-width: 55px;
    font-family: 'Open Sans Condensed', 'Arial';
}

.highest-service-point div.average {
    background-color: #00BA54;
}

.lowest-service-point div.average {
    background-color: #F8382B;
}

.highest-lowest-service-point div.average span.percent {
    font-size: 0.6rem;
}

.highest-lowest-service-point div.name {
    font-size: 0.85rem;
    color: #484848;
    width: 100%;
}

.highest-service-point div.name {
    background-color: #EBF9F1;
}

.lowest-service-point div.name {
    background-color: #FFEEED;
}

.highest-lowest-service-point div.name div.explanation {
    font-size: 0.73rem;
    marign-left: 2px;
}

.snapshots-card div.service-points-parent {
    margin: 0;
    padding: 0;
}

.snapshots-card div.service-points-parent h3 {
    text-align: left;
    background-color: #F5F5F5;
    padding: 3px 12px;
    font-size: 0.8rem;
    font-weight: 300;
    margin: 12px 0 0 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 3px;
}

.snapshots-card div.service-points {
    border: 1px solid #F5F5F5;
    margin-top: 0px;
    border-radius: 0 0 3px 3px;
    padding: 5px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-flow: row wrap;
}

.service-point-list-item {

    padding: 6px;
    float: left;
    margin: 3px;
    background-color: #F5F5F5;
    font-size: 0.55rem;
    border-radius: 2px;
}

.service-point-list-item span {
    line-height: 3;
    margin-left: 5px;
}

.service-point-list-item div.summary-circle {
    margin-top: 5px;
    float: left;
    border-radius: 50%;
    height: 16px;
    width: 16px;
}

@media (max-width: 992px) {

    /* .mobile-container{
        margin: 0px;
        padding: 0px;
    } */
    .snapshots-page-bg {
        width: 100% !important;
        padding: 0px;
    }

    .snapshots-page-title h1.left {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
    }

    .snapshots-page-title p {
        text-align: left;
    }

    .snapshots-card {
        width: 345px;
        max-width: 345px;
        min-width: 345px;
    }

}

.google-badge {
    display: flex;
}

.google-badge .text {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: grey;
    padding-top: 4px;
}

.google-badge .badge {
    float: right;
    margin-left: auto;
    padding-top: 7px;
}
