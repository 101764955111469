
.action-header {
    background-color: #fcc300;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: -20px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.actions-header-desc {
    margin-bottom: 80px;
    margin-right: 40%;
}

.action-header h1 {
    font-weight: 600;
    font-size: 40px;
}

.action-header h4 {
    margin-left: 40%;
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 400;
    font-size: 20px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
}

h1 {
    text-align: center;
    margin: 20px auto;
}

.action-card-parent {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto;
}

.action-card {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    margin: 15px auto;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 24px 18px -10px rgba(23, 29, 33, .08);
}

.action-card .show-original-idea {	
    font-style: italic;	
    font-size: 15px !important;	
    font-family: 'Open Sans', sans-serif;	
    color: #ffffff;	
    padding: 15px;	
    line-height: 23px !important;	
    width: 100%;	
}	

.action-card .title {	
    padding-left: 15px;	
    padding-right: 15px;	
    padding-top: 8px;	
    padding-bottom: 0px;	
    font-weight: 900;	
}	

.action-card .description {	
    padding-top: 0px;	
}	

.action-card .ideafeed-item-details {	
    border-radius: 0;	
}	

.action-card .translation-container {	
    padding-left: 15px;	
    padding-right: 15px;	
}	

.action-card em {	
    background-color: #ffc300;	
    margin: 4px;	
    border-radius: 4px;	
    color: #fff;	
    padding: 3px;	
}	

.action-card .card {	
    margin: 10px;	
}

.action-top-details {
    display: flex;
    flex-direction: column;
}

.action-card-body {
    margin-top: 15px;
    width: auto;
    margin-right: 15px;
    text-align: justify;
}

.action-img {
    /* width: 100%; */
    /* padding: 10px 10px;
    border-color: #fff; */
    border: none;
}

.action-img img {
    /* width: 120%; */
    /* height: 266px;
    border-color: none; */
    border-radius: 12px;
}

a {
    width: auto;
    /* margin-right: 30px; */
}

.action-links:hover .action-source:hover {
    margin-right: 10px;
    color: #757575;
}

.action-icon:hover {
    color: #757575;
    margin-right: 5px;
}

.footer-text:hover {
    padding-right: 5px;
}

.footer-text {	
    font-size: 15px;	
}

.action-icon {
    margin-right: 20px;
}

.action-source {
    margin-right: 5px;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-weight: bolder;
}

.action-rate {
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 300;
    font-size: 18px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 25px;
}

.action-details {
    margin-top: -20px;
    margin-left: 50px;
    /* margin-right: -28px; */
}

.action-desc {
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 300;
    font-size: 15px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
}

.action-title {
    font-family: 'Open Sans', sans-serif;
    ;
    font-weight: 300;
    font-size: 18px;
    color: #2b3841;
    letter-spacing: 0;
    line-height: 24px;
    /* margin-right: 30%; */
    margin-bottom: -20px;
    text-align: left;
}

.action-author {
    font-family: 'Open Sans', sans-serif;
    ;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    color: #757575;
    letter-spacing: 0;
    line-height: 24px;
}

.action-content {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.action-time,
.action-sector,
.action-area,
.action-tag {
    margin-right: 8px;
    font-size: 11px;
    color: #757575;
}

.action-bottom-details {
    background-color: #eee;
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
}

.action-footer {
    width: 100%;
    min-height: 56px;
    background: #F5F5F5;
    border-radius: 0px 0px 12px 12px;
    padding-top: 14px;
}

.footer-desc {
    padding-left: 12px;
}

.footer-desc p {	
    font-size: 15px;
}

.action-links {
    text-align: end;
}

.pageGo {
    padding: 10px;
    color: #F5F5F5;
    background-color: #F5F5F5;
    float: right;
    margin-top: 80px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    padding: 9px 30px;
}

@media only screen and (max-width: 600px) {
    .action-card {
        padding: 0px;
        /* height: 65%; */
        width: 100%;
        flex-direction: column;
    }
    .action-top-details {
        flex-direction: column;
    }
    .action-card-body {
        /* flex-direction: column;
        margin-left: -110px;
        height: 10%;
        margin-top: 280px;
        width: 150%; */
        margin-left: 15px;
    }
    .action-content {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin-left: 5 5px;
    }
    .action-time,
    .action-sector,
    .action-area {
        margin-right: 4px;
    }
    .action-sector {
        margin-top: -15px;
    }
    .action-footer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-right: 24px;	
        padding-left: 24px;
    }
    .footer-desc {
        text-align: center;
        margin-top: -15px;
        margin-left: -10px;
        width: 130%;
    }
    .action-links {
        /* margin-top: 70px;
        margin-left: -160px; */
        margin-bottom: 10px;
    }
}

/* @media only screen and (max-width: 400px) {
    .action-card {
        height: 65%;
        width: 80%;
        flex-direction: column;
    }
} */

.no-actions {
    text-align: center;
    font-style: italic;
}

.page-title {
    position: relative;
}

.page-title button {
    position: absolute;
    right: 0;
    margin-top: 70px;
}

.action-preview {
    border: 1px solid #F5F5F5;
}
