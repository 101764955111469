@import url(https://fonts.googleapis.com/css?family=Lato:700);

*,
*:before,
*:after {
    box-sizing: border-box;
}

.previous-size {
    font-size: 4em;
    margin-top: 0.3em;
}

.left {
    margin-left: -10px;
}

.right {
    margin-right: -10px;
}

.region-size {
    font-size: 6em;
}

.charts-container:after {
    clear: both;
    content: "";
    display: table;
}

.pie-wrapper {
    height: 1em;
    width: 1em;
    float: left;
    margin: 15px;
    position: relative;
}

.pie-wrapper.large {
    margin-bottom: 2px;
}

.pie-wrapper:nth-child(3n + 1) {
    clear: both;
}

.pie-wrapper .pie {
    height: 100%;
    width: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
    left: 0;
    position: absolute;
    top: 0;
}

.pie-wrapper .pie .half-circle {
    height: 100%;
    width: 100%;
    border: 0.07em solid #00BA54;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0);
    left: 0;
    position: absolute;
    top: 0;
}

.numbers-small-card {
    font-family: 'Open Sans Condensed', 'Arial';
}

.left-right-pie{
    border-width:0.1em !important;
}

.left-right-caption{
    font-size: 22px !important;
    line-height: 1.8em !important;
    padding-left:4px !important;
    left: 0.3em !important;
}

.happy {
    border-color: #00BA54 !important;
}

.unhappy {
    border-color: #F8382B !important;
}

.insignificant {
    border-color: #F5F5F5 !important;
}

.pie-wrapper .label {
    background: #34495e;
    border-radius: 50%;
    bottom: 0.4em;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: 0.25em;
    left: 0.5em;
    line-height: 2.8em;
    position: absolute;
    right: 0.4em;
    text-align: center;
    top: 0.4em;
}

.pie-wrapper .label .smaller {
    color: #bdc3c7;
    font-size: 0.45em;
    padding-bottom: 20px;
    vertical-align: super;
}

.pie-wrapper .label .smaller-responses {
    font-size: 0.37em;
    display: block;
    line-height: 0;
    margin-top: -18px;
}

.pie-wrapper .label .smaller-responses.previous-next {
    margin-top: -5px;
}

.pie-wrapper .pie-shadow {
    height: 100%;
    width: 100%;
    border: 0.07em solid #f5f5f5;
    border-radius: 50%;
}

.pie-wrapper.style-2 .label {
    background: none;
    color: #000000;
}

.pie-wrapper.style-2 .label .smaller {
    color: #000000;
}

.pie-wrapper.progress-1 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-1 .pie .left-side {
    transform: rotate(3.6deg);
}

.pie-wrapper.progress-1 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-2 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-2 .pie .left-side {
    transform: rotate(7.2deg);
}

.pie-wrapper.progress-2 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-3 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-3 .pie .left-side {
    transform: rotate(10.8deg);
}

.pie-wrapper.progress-3 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-4 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-4 .pie .left-side {
    transform: rotate(14.4deg);
}

.pie-wrapper.progress-4 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-5 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-5 .pie .left-side {
    transform: rotate(18deg);
}

.pie-wrapper.progress-5 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-6 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-6 .pie .left-side {
    transform: rotate(21.6deg);
}

.pie-wrapper.progress-6 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-7 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-7 .pie .left-side {
    transform: rotate(25.2deg);
}

.pie-wrapper.progress-7 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-8 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-8 .pie .left-side {
    transform: rotate(28.8deg);
}

.pie-wrapper.progress-8 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-9 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-9 .pie .left-side {
    transform: rotate(32.4deg);
}

.pie-wrapper.progress-9 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-10 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-10 .pie .left-side {
    transform: rotate(36deg);
}

.pie-wrapper.progress-10 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-11 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-11 .pie .left-side {
    transform: rotate(39.6deg);
}

.pie-wrapper.progress-11 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-12 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-12 .pie .left-side {
    transform: rotate(43.2deg);
}

.pie-wrapper.progress-12 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-13 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-13 .pie .left-side {
    transform: rotate(46.8deg);
}

.pie-wrapper.progress-13 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-14 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-14 .pie .left-side {
    transform: rotate(50.4deg);
}

.pie-wrapper.progress-14 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-15 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-15 .pie .left-side {
    transform: rotate(54deg);
}

.pie-wrapper.progress-15 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-16 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-16 .pie .left-side {
    transform: rotate(57.6deg);
}

.pie-wrapper.progress-16 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-17 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-17 .pie .left-side {
    transform: rotate(61.2deg);
}

.pie-wrapper.progress-17 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-18 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-18 .pie .left-side {
    transform: rotate(64.8deg);
}

.pie-wrapper.progress-18 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-19 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-19 .pie .left-side {
    transform: rotate(68.4deg);
}

.pie-wrapper.progress-19 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-20 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-20 .pie .left-side {
    transform: rotate(72deg);
}

.pie-wrapper.progress-20 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-21 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-21 .pie .left-side {
    transform: rotate(75.6deg);
}

.pie-wrapper.progress-21 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-22 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-22 .pie .left-side {
    transform: rotate(79.2deg);
}

.pie-wrapper.progress-22 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-23 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-23 .pie .left-side {
    transform: rotate(82.8deg);
}

.pie-wrapper.progress-23 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-24 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-24 .pie .left-side {
    transform: rotate(86.4deg);
}

.pie-wrapper.progress-24 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-25 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-25 .pie .left-side {
    transform: rotate(90deg);
}

.pie-wrapper.progress-25 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-26 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-26 .pie .left-side {
    transform: rotate(93.6deg);
}

.pie-wrapper.progress-26 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-27 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-27 .pie .left-side {
    transform: rotate(97.2deg);
}

.pie-wrapper.progress-27 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-28 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-28 .pie .left-side {
    transform: rotate(100.8deg);
}

.pie-wrapper.progress-28 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-29 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-29 .pie .left-side {
    transform: rotate(104.4deg);
}

.pie-wrapper.progress-29 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-30 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-30 .pie .left-side {
    transform: rotate(108deg);
}

.pie-wrapper.progress-30 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-31 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-31 .pie .left-side {
    transform: rotate(111.6deg);
}

.pie-wrapper.progress-31 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-32 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-32 .pie .left-side {
    transform: rotate(115.2deg);
}

.pie-wrapper.progress-32 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-33 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-33 .pie .left-side {
    transform: rotate(118.8deg);
}

.pie-wrapper.progress-33 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-34 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-34 .pie .left-side {
    transform: rotate(122.4deg);
}

.pie-wrapper.progress-34 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-35 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-35 .pie .left-side {
    transform: rotate(126deg);
}

.pie-wrapper.progress-35 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-36 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-36 .pie .left-side {
    transform: rotate(129.6deg);
}

.pie-wrapper.progress-36 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-37 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-37 .pie .left-side {
    transform: rotate(133.2deg);
}

.pie-wrapper.progress-37 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-38 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-38 .pie .left-side {
    transform: rotate(136.8deg);
}

.pie-wrapper.progress-38 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-39 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-39 .pie .left-side {
    transform: rotate(140.4deg);
}

.pie-wrapper.progress-39 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-40 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-40 .pie .left-side {
    transform: rotate(144deg);
}

.pie-wrapper.progress-40 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-41 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-41 .pie .left-side {
    transform: rotate(147.6deg);
}

.pie-wrapper.progress-41 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-42 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-42 .pie .left-side {
    transform: rotate(151.2deg);
}

.pie-wrapper.progress-42 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-43 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-43 .pie .left-side {
    transform: rotate(154.8deg);
}

.pie-wrapper.progress-43 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-44 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-44 .pie .left-side {
    transform: rotate(158.4deg);
}

.pie-wrapper.progress-44 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-45 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-45 .pie .left-side {
    transform: rotate(162deg);
}

.pie-wrapper.progress-45 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-46 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-46 .pie .left-side {
    transform: rotate(165.6deg);
}

.pie-wrapper.progress-46 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-47 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-47 .pie .left-side {
    transform: rotate(169.2deg);
}

.pie-wrapper.progress-47 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-48 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-48 .pie .left-side {
    transform: rotate(172.8deg);
}

.pie-wrapper.progress-48 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-49 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-49 .pie .left-side {
    transform: rotate(176.4deg);
}

.pie-wrapper.progress-49 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-50 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-50 .pie .left-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-50 .pie .right-side {
    display: none;
}

.pie-wrapper.progress-51 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-51 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-51 .pie .left-side {
    transform: rotate(183.6deg);
}

.pie-wrapper.progress-51 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-52 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-52 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-52 .pie .left-side {
    transform: rotate(187.2deg);
}

.pie-wrapper.progress-52 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-53 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-53 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-53 .pie .left-side {
    transform: rotate(190.8deg);
}

.pie-wrapper.progress-53 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-54 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-54 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-54 .pie .left-side {
    transform: rotate(194.4deg);
}

.pie-wrapper.progress-54 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-55 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-55 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-55 .pie .left-side {
    transform: rotate(198deg);
}

.pie-wrapper.progress-55 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-56 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-56 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-56 .pie .left-side {
    transform: rotate(201.6deg);
}

.pie-wrapper.progress-56 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-57 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-57 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-57 .pie .left-side {
    transform: rotate(205.2deg);
}

.pie-wrapper.progress-57 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-58 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-58 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-58 .pie .left-side {
    transform: rotate(208.8deg);
}

.pie-wrapper.progress-58 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-59 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-59 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-59 .pie .left-side {
    transform: rotate(212.4deg);
}

.pie-wrapper.progress-59 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-60 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-60 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-60 .pie .left-side {
    transform: rotate(216deg);
}

.pie-wrapper.progress-60 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-61 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-61 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-61 .pie .left-side {
    transform: rotate(219.6deg);
}

.pie-wrapper.progress-61 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-62 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-62 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-62 .pie .left-side {
    transform: rotate(223.2deg);
}

.pie-wrapper.progress-62 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-63 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-63 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-63 .pie .left-side {
    transform: rotate(226.8deg);
}

.pie-wrapper.progress-63 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-64 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-64 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-64 .pie .left-side {
    transform: rotate(230.4deg);
}

.pie-wrapper.progress-64 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-65 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-65 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-65 .pie .left-side {
    transform: rotate(234deg);
}

.pie-wrapper.progress-65 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-66 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-66 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-66 .pie .left-side {
    transform: rotate(237.6deg);
}

.pie-wrapper.progress-66 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-67 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-67 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-67 .pie .left-side {
    transform: rotate(241.2deg);
}

.pie-wrapper.progress-67 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-68 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-68 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-68 .pie .left-side {
    transform: rotate(244.8deg);
}

.pie-wrapper.progress-68 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-69 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-69 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-69 .pie .left-side {
    transform: rotate(248.4deg);
}

.pie-wrapper.progress-69 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-70 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-70 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-70 .pie .left-side {
    transform: rotate(252deg);
}

.pie-wrapper.progress-70 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-71 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-71 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-71 .pie .left-side {
    transform: rotate(255.6deg);
}

.pie-wrapper.progress-71 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-72 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-72 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-72 .pie .left-side {
    transform: rotate(259.2deg);
}

.pie-wrapper.progress-72 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-73 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-73 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-73 .pie .left-side {
    transform: rotate(262.8deg);
}

.pie-wrapper.progress-73 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-74 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-74 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-74 .pie .left-side {
    transform: rotate(266.4deg);
}

.pie-wrapper.progress-74 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-75 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-75 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-75 .pie .left-side {
    transform: rotate(270deg);
}

.pie-wrapper.progress-75 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-76 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-76 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-76 .pie .left-side {
    transform: rotate(273.6deg);
}

.pie-wrapper.progress-76 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-77 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-77 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-77 .pie .left-side {
    transform: rotate(277.2deg);
}

.pie-wrapper.progress-77 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-78 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-78 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-78 .pie .left-side {
    transform: rotate(280.8deg);
}

.pie-wrapper.progress-78 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-79 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-79 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-79 .pie .left-side {
    transform: rotate(284.4deg);
}

.pie-wrapper.progress-79 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-80 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-80 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-80 .pie .left-side {
    transform: rotate(288deg);
}

.pie-wrapper.progress-80 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-81 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-81 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-81 .pie .left-side {
    transform: rotate(291.6deg);
}

.pie-wrapper.progress-81 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-82 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-82 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-82 .pie .left-side {
    transform: rotate(295.2deg);
}

.pie-wrapper.progress-82 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-83 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-83 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-83 .pie .left-side {
    transform: rotate(298.8deg);
}

.pie-wrapper.progress-83 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-84 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-84 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-84 .pie .left-side {
    transform: rotate(302.4deg);
}

.pie-wrapper.progress-84 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-85 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-85 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-85 .pie .left-side {
    transform: rotate(306deg);
}

.pie-wrapper.progress-85 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-86 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-86 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-86 .pie .left-side {
    transform: rotate(309.6deg);
}

.pie-wrapper.progress-86 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-87 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-87 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-87 .pie .left-side {
    transform: rotate(313.2deg);
}

.pie-wrapper.progress-87 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-88 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-88 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-88 .pie .left-side {
    transform: rotate(316.8deg);
}

.pie-wrapper.progress-88 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-89 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-89 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-89 .pie .left-side {
    transform: rotate(320.4deg);
}

.pie-wrapper.progress-89 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-90 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-90 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-90 .pie .left-side {
    transform: rotate(324deg);
}

.pie-wrapper.progress-90 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-91 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-91 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-91 .pie .left-side {
    transform: rotate(327.6deg);
}

.pie-wrapper.progress-91 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-92 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-92 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-92 .pie .left-side {
    transform: rotate(331.2deg);
}

.pie-wrapper.progress-92 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-93 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-93 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-93 .pie .left-side {
    transform: rotate(334.8deg);
}

.pie-wrapper.progress-93 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-94 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-94 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-94 .pie .left-side {
    transform: rotate(338.4deg);
}

.pie-wrapper.progress-94 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-95 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-95 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-95 .pie .left-side {
    transform: rotate(342deg);
}

.pie-wrapper.progress-95 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-96 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-96 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-96 .pie .left-side {
    transform: rotate(345.6deg);
}

.pie-wrapper.progress-96 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-97 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-97 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-97 .pie .left-side {
    transform: rotate(349.2deg);
}

.pie-wrapper.progress-97 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-98 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-98 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-98 .pie .left-side {
    transform: rotate(352.8deg);
}

.pie-wrapper.progress-98 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-99 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-99 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-99 .pie .left-side {
    transform: rotate(356.4deg);
}

.pie-wrapper.progress-99 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper.progress-100 .pie {
    clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-100 .pie .half-circle {
    border-color: #00BA54;
}

.pie-wrapper.progress-100 .pie .left-side {
    transform: rotate(360deg);
}

.pie-wrapper.progress-100 .pie .right-side {
    transform: rotate(180deg);
}

.pie-wrapper--solid {
    border-radius: 50%;
    overflow: hidden;
}

.pie-wrapper--solid:before {
    border-radius: 0 100% 100% 0/50%;
    content: "";
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
}

.pie-wrapper--solid .label {
    background: transparent;
}

.pie-wrapper--solid.progress-65 {
    background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}

.pie-wrapper--solid.progress-65:before {
    background: #e67e22;
    transform: rotate(126deg);
}

.pie-wrapper--solid.progress-25 {
    background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}

.pie-wrapper--solid.progress-25:before {
    background: #34495e;
    transform: rotate(-270deg);
}

.pie-wrapper--solid.progress-88 {
    background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}

.pie-wrapper--solid.progress-88:before {
    background: #3498db;
    transform: rotate(43.2deg);
}
