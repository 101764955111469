.no-page {
    text-align: center;
    min-height: calc(100vh - 248px);
}

.no-page-container {
    width: 100%;
    top: 145px;
    background: #FFFFFF;
    box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
    border-radius: 12px;
    margin-top: 145px;
}

.no-page-content {
    display: block;
    padding-top: 50px;
    padding-right: 46px;
    padding-left: 48px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.no-page-buttons {
    width: 100%;
    height: 56px;
    top: 251px;
    background: #F5F5F5;
    border-radius: 0px 0px 12px 12px;
}

.no-page-option {
    padding-top: 16px;
}

.no-page-back {
    cursor: pointer;
}

a, a:hover {
    color: black;
    text-decoration: none;
}

.no-page-icon {
    width: 73px;
    height: 73px;
    position: absolute;
    top: 106px;
    left: calc(50% - 36px);
    border-radius: 50%;
}

.no-page-icon img {
    height: 92px;
    position: relative;
    top: -8px;
}
