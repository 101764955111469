 /* ----------- Loading ------------------*/

 /* .loading {
    background-color: #171D21;
    margin: 0 auto;
    width: 64px;
    text-align: center;
    position: fixed;
    z-index: 999999;
    bottom: 16px;
    left: calc(50% - 32px);
    border-radius: 24px;
    box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14);
    transition: 0.3s;
    opacity: 1;
}

.loading > div {
    width: 4px;
    height: 4px;
    background-color: #FFFFFF;
    padding: 8px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin-top: 8px;
}

.loading .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loading .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
    -webkit-transform: scale(1.0);
        transform: scale(0.6);
    }
} */

.loading-container{
    background-color: #171D21;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    text-align: center;
    position: fixed;
    z-index: 999999;
    /* bottom: calc(50% - 32px); */
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    border-radius: 8px;
    /* box-shadow: 0 18px 24px -6px rgba(23,29,33,0.14); */
    transition: 0.3s;
    opacity: 1;
    transform: scale(0.75);
}

.loading-overlay{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2000;
    cursor: wait;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.loader {
  margin: 15px auto;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
