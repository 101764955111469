.ideafeed-page{
    background-size: 100% 360px;
}

.ideafeed-page-title{
    margin-top: 60px;
}

.ideafeed-page-title h1{
    margin-left: 0px;
}

.ideafeed-page-bg {
    margin-top: 28px;
    margin-bottom: 56px;
    min-height: 800px;
    width: 100%;
}

.ideafeed-page .globalfilter-container{
    top: 0px;
    margin: 0px;
    position: inherit;
    width: 100%;
    margin-bottom: -16px;
    margin-top: 24px;
}

.ideafeed-page-head{
    width: 100%;
    height: 56px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
}

.ideafeed-results{
    padding-left: 15px;
}

.ideafeed-page-headspacer{
    flex: 1;
    flex-grow: 3;
}

.ideafeed-print{
    width: 120px;
    flex: 3;
    flex-grow: 1;
    text-align: right;
}

.ideafeed-feed{
    padding: 0px;
    margin: 0px;
}

.ideafeed-item{
    background: #FFFFFF;
    box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
    border-radius: 12px;
    margin-bottom: 20px;
}

.ideafeed-item em {
    background-color: #FFC300;
    margin: 4px;
    border-radius: 4px;
    color: #ffffff;
    padding:3px;
}

.ideafeed-item .small{
    /* background-color: darkseagreen; */
    margin-top: 8px;
    margin-bottom: 6px;
    margin-left: 20px;
}

.ideafeed-item-idea .idea {
    font-size: 20px;
    width: 80%;
}

.ideafeed-item-idea{
    flex: 1;
    margin-bottom: 0px;
    padding: 24px;
}

.ideafeed-item-idea button.tag {
    border: none;
    background-color: transparent;
    padding-top: 5px;
    padding-right: 0px;
    padding-left: 5px;
    font-size: 12px;
    color: #2B3841;
}

.ideafeed-item-idea button.tag:hover {
    color: grey;
}

.ideafeed-item-idea button.tag:focus,
.ideafeed-item-idea button.tag:active {
    box-shadow: none !important;
    background-color: transparent !important;
}

.ideafeed-item-details {
    width: 100%;
    min-height: 56px;
    background: #F5F5F5;
    border-radius: 0px 0px 12px 12px;
    padding-top: 1px;
}

.ideafeed-item-details .toggle {
    text-align: center;
    padding: 15px;
    cursor: pointer;
}

.ideafeed-item-details-idea {
    min-height: 141px;
    background: #1C3850;
    border-radius: 8px;
    margin-right: 27px;
    margin-left: 27px;
    margin-top: 26px;
}

.ideafeed-item-details-idea .header {
    height: 56px;
    left: 25px;
    top: 251px;
    background: #122A3E;
    border-radius: 12px 12px 0px 0px;
    padding: 15px;
}

.ideafeed-item-details-idea .header-text {
    font: 14px 'Open Sans', sans-serif;
    color: white;
}

.ideafeed-item-starred {
    width: 40px;
    height: 40px;
    margin-left: auto;
}

.ideafeed-item-starred .icon {
    width: 32px;
    height: 32px;
}

/* pagination styles */

.pagination{
    margin-bottom: 48px;
    margin-left: calc(50% - 175px)

}

.pagination ul{
    display: inline-block;

}

.page-item.active .page-link {
    background-color: #ffc300 !important;
    border-color: #ffc300;
}

.page-link, .page-item .disabled {
    color: #2B3841;
}


@media (max-width: 992px) {

    /* .mobile-container{
        margin: 0px;
        padding: 0px;
    } */

    .ideafeed-page-bg{
        width: 100% !important;
        padding: 0px;
    }

    .ideafeed-page-title h1.left{
        font-size: 24px;
        line-height: 32px;
        text-align: left;
    }

    .ideafeed-page-title p{
        text-align: left;
    }

}

.show-original {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: grey;
}

.show-original-idea {
    font-style: italic;
    font-size: 18px !important;
    font-family: 'Open Sans', sans-serif;
    color: #ffffff;
    padding: 15px;
    line-height: 23px !important;
    width: 80%;
}

.translation-container {
    padding-bottom: 15px;
}

.translation-container .google-badge {
    float: right;
}

.translation-container .text {
    float: left;
}

.satisfaction {
    margin: 0;
    display: flex;
}
.satisfaction-keyword-col {
    flex: 1;
    margin-left: 10px;
}

.satisfaction-keywords {
    width: 100%;
    height: 192px;
    background: #1C3850;
    border-radius: 12px;
    display: inline-block;
    height: auto;
    overflow: visible;
}

.satisfaction-keywords .header {
    display: block;
    flex-direction: row;
    min-height: 56px;
    background: #122A3E;
    border-radius: 12px 12px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 14px;
    padding-top: 17px;
    height: auto;
}

.satisfaction-stats {
    width: 265px;
    height: 192px;
    background: #1C3850;
    border-radius: 12px;
    margin-bottom: 20px;
    float: left;
}

.satisfaction-stats .header {
    height: 56px;
    background: #122A3E;
    border-radius: 12px 12px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 24px;
}

.header-toggle {
    color: #b7b6b6;
    padding-right: 30px;
    padding-left: 6px;
    text-align: right;
}

.header-toggle .options {
    cursor: pointer;
    font-size: 13px;
}

.header-toggle .options:hover {
    color: #ffffff;
}

.header-title {
    color: #FFFFFF;
    padding-left: 32px;
}

.selected-keywords {
    font-style: italic;
    font-size: 13px;
    color: #b7b6b6;
    line-height: 2;
}

.keywords-container {
    min-height: 136px;
}

.keywords {
    padding: 15px;
}

.keywords img {
    width: 20px;
    margin-right: 20px;
}

.keyword-option {
    background: #122A3E;
    border-color: #122A3E;
    border-radius: 8px;
    color: #ffffff;
    margin-right: 8px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
    padding: 6px;
}

.keyword-option:hover {
    background: #FFC300;
    color: #ffffff;
}

.keywords-column {
    margin-left: -17px;
}

.top-border {
    border-top: 2px solid #122A3E;
}

.satisfaction-face {
    display: block;
}


.stats {
    display: flex;
}

.stats-pie {
    font-size: 5rem;
    padding-left: 20px;
}

.stats-pie .pie-wrapper .label {
    top: 0.73em;
}

.stats-pie .pie-wrapper .pie-shadow {
    border: 0.07em solid #465C6D;
}

.stats-pie .pie-wrapper.style-2 .label {
    color: #ffffff;
}

.stats-pie .pie-wrapper.style-2 .label .smaller {
    color: #ffffff;
}

.stats-numbers {
    padding: 10px;
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 48px;
    display: block;
    align-items: center;
}

.stats-numbers img {
    width: 20px;
}

.stats-numbers span{
    padding-left: 10px;
}

.no-results {
    color: #b7b6b6;
    font-size: 12px;
    font-style: italic;
    line-height: 15px;
    margin-bottom: 8px;
    padding: 6px;
}

@media (max-width: 1200px) {
    .satisfaction {
        display: block;
        margin-bottom: 20px;
    }

    .satisfaction-keyword-col {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .satisfaction-keywords {
        margin-left: 0px;
        width: 100%;
        margin-right: 15px;
        max-width: unset;
    }

    .keywords-column {
        margin-left: -8px;
    }
    .satisfaction-stats {
        float: right;
    }
}

@media (max-width: 767px) {
    .satisfaction-stats {
        width: 100%;
    }
    .stats{
        justify-content: center;
    }
    .header{
        justify-content: center;
    }
}
