.privacy-policy-title {
    margin-top: 136px;
}

.privacy-policy-title h1 {
    margin-bottom: 30px;
}

.privacy-policy p {
    font-family: 'Arial';
}